import styled from 'styled-components'
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material'
import { colors } from '../context/theme-context/'

const StyledRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
`
const StyledCell = styled(TableCell)`
  border-left: 1px solid ${colors.gray4};
  border-right: 1px solid ${colors.gray4};
`

export default function BasicTable({ rows }: any) {
  return (
    <TableContainer component="div" style={{}}>
      <Table size="small" stickyHeader>
        <TableBody>
          {rows.map((row: any, i: any) => (
            <StyledRow key={i}>
              {Object.entries(row).map(([key, value]) => (
                <StyledCell data-cy={`matchTable_${i}_${key}`} key={key}>
                  {value}
                </StyledCell>
              ))}
            </StyledRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
