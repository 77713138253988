//@ts-nocheck
import { colors, theme as currentTheme } from '../../context/theme-context'
import { createTheme, adaptV4Theme } from "@mui/material/styles";

function makeTableTheme({ maxCellWidth = '50', outOfDate = false }) {
  const theme = createTheme(adaptV4Theme({
    ...currentTheme,
    overrides: {
      ...currentTheme.overrides,
      MUIDataTableBodyCell: {
        root: {
          fontFamily: 'Rubik',
          maxWidth: maxCellWidth + 'ch',
          color: outOfDate ? colors.gray6 : colors.gray10,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  }))
  return theme
}

const makeThemes = (maxCellWidth) => {
  const theme = makeTableTheme({ maxCellWidth })
  const disabledTheme = makeTableTheme({ maxCellWidth, outOfDate: true })
  return [theme, disabledTheme]
}
const [theme, disabledTheme] = makeThemes()

export default makeThemes
export { theme, disabledTheme }
