import Button from 'components/Button'
import PlayButton from '../PlayButton'
import FavoriteButton from '../FavoriteButton'
import LoadButton from 'components/Button/LoadButton'
import LinkedInLogo from 'icons/linkedInLogo.png'
import LiveRampLogo from 'icons/liveRampLogo.png'
import { ReactComponent as FacebookIcon } from 'icons/24px/Facebook.svg'
import { ReactComponent as NetWiseIcon } from 'icons/24px/NetWiseLogo.svg'
import { BiEdit } from 'react-icons/bi'
import LinkButton from 'components/Button/LinkButton'
import RemoteDataTable from 'components/RemoteDataTable'
import { ConditionalTooltip } from 'components/Tooltip'
import SpecialTooltip from 'components/SpecialTooltip'
import Accordion from 'components/Accordion'
import styled from 'styled-components'
import { useAuth } from 'context/auth-context'
import { useAlerts } from 'context/alert-context'
import { useSnackbar } from 'context/snackbar-context'

const ACTION_CELL_WIDTH = 20
const FAVORITES_CELL_WIDTH = 10
const NAME_CELL_WIDTH = 85
const DATE_CELL_WIDTH = 15
const RECORDS_CELL_WIDTH = 25

type Props = {
  needsUpgrade: any
  filtersHaveUpdated: any
  handleQuickExport: any
  refreshSavedExports: any
  exportsAPI: string
  exportsIdAPI: any
  updateFavoriteStatus: any
  viewExport: any
  runExport: any
  userData: any
  editExport: any
  updatedDTCache: any
  calculate: any
}

export default function SavedExportsTable(props: Props) {
  const { client, adminMode } = useAuth()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage } = useSnackbar()
  return (
    (<Accordion
          title={
            <LeftSideSummary>
              <div>Saved Exports</div>
            </LeftSideSummary>
          }
          Widgets={
            <ControlDiv>
              <div>
                <SpecialTooltip
                  text="Your account doesn't have credits and/or permission to export audiences. Please use the chat to upgrade your account."
                  condition={props.needsUpgrade}
                >
                  <Button
                    text={<PlayButton text="Run Quick Export" />}
                    testId="runQuickExport"
                    disabled={!props.filtersHaveUpdated || props.needsUpgrade}
                    size="medium"
                    variant="primary"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      props.handleQuickExport()
                    }}
                    textStyle={{ fontWeight: 'bold' }}
                    style={{ padding: '0.5625rem' }}
                  />
                </SpecialTooltip>
              </div>
            </ControlDiv>
          }
        >
      <div style={{ width: '100%', paddingTop: '1rem' }}>
        <RemoteDataTable
          client={client}
          adminMode={adminMode}
          sendAlert={sendAlert}
          setSnackbarMessage={setSnackbarMessage}
          refreshState={props.refreshSavedExports}
          title="savedExportsTab"
          url={props.exportsAPI}
          deleteAPI={props.exportsIdAPI}
          autosaveControl={true}
          expandableRows={false}
          selectableRows="multiple"
          controls={true}
          deleteControl={true}
          searchControl={true}
          initialSortOrder={'-favorite,-modified'}
          columnOrderMap={{
            favorite: 'favorite',
            name: 'name',
            modified: 'modified',
          }}
          rowsOnPage={5}
          columns={[
            {
              name: 'favorite',
              label: ' ',
              options: {
                setCellProps: () => ({
                  style: { width: FAVORITES_CELL_WIDTH },
                }),
              },
            },
            {
              name: 'icon',
              label: ' ',
              options: {
                setCellProps: () => ({
                  style: { width: FAVORITES_CELL_WIDTH },
                }),
              },
            },
            {
              name: 'name',
              label: 'Name',
              options: {
                setCellProps: () => ({
                  style: { minWidth: NAME_CELL_WIDTH },
                }),
              },
            },
            {
              name: 'modified',
              label: 'Last Modified',
              options: {
                customBodyRender: (value: any, _: any, __: any) =>
                  new Date(value.raw).toLocaleString('en-US', {
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  }),
                sortCompare: (order: any) => (a: any, b: any) => {
                  if (order === 'asc') {
                    return a.data.sortable > b.data.sortable ? 1 : -1
                  }
                  return a.data.sortable < b.data.sortable ? 1 : -1
                },
                setCellProps: () => ({
                  style: { maxWidth: DATE_CELL_WIDTH },
                }),
              },
            },
            {
              name: 'records_and_credits',
              label: 'Records and Credits',
              options: {
                sort: false,
                setCellProps: () => ({
                  style: { maxWidth: RECORDS_CELL_WIDTH },
                }),
              },
            },
            {
              name: 'edit',
              label: ' ',
              options: {
                sort: false,
                setCellProps: () => ({
                  style: { width: ACTION_CELL_WIDTH },
                }),
              },
            },
            {
              name: 'run',
              label: ' ',
              options: {
                sort: false,
                setCellProps: () => ({
                  style: { width: ACTION_CELL_WIDTH },
                }),
              },
            },
          ]}
          customRowRender={(rowData: any) => {
            const exportsTableData = rowData?.map((item: any, idx: any) => {
              const updatedItem: {
                favorite?: JSX.Element
                icon?: JSX.Element
                name?: JSX.Element
                created_by?: String
                modified?: JSX.Element
                edit?: JSX.Element
                run?: JSX.Element
                records_and_credits?: JSX.Element
              } = {}

              updatedItem.favorite = (
                <FavoriteButton
                  status={item.favorite}
                  updateFavoriteStatus={() => props.updateFavoriteStatus(item)}
                  disabled={!props.filtersHaveUpdated}
                />
              )

              let icon
              const input = item.name.toLowerCase()
              if (input.match(/facebook/g)) {
                icon = <FacebookIcon height={'42px'} width={'42px'} />
              } else if (input.match(/liveramp/g)) {
                icon = (
                  <img
                    src={LiveRampLogo}
                    height={'42px'}
                    width={'42px'}
                    alt="LiveRamp Logo"
                  />
                )
              } else if (input.match(/linkedin/g)) {
                icon = (
                  <img
                    src={LinkedInLogo}
                    height={'42px'}
                    width={'42px'}
                    alt="LinkedIn Logo"
                  />
                )
              } else {
                icon = <NetWiseIcon height={'42px'} width={'42px'} />
              }

              updatedItem.icon = (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {icon}
                </div>
              )

              updatedItem.name = (
                <ConditionalTooltip
                  text={item.name}
                  maxLength={NAME_CELL_WIDTH}
                  render={(text: any) => (
                    <LinkButton
                      text={text}
                      onClick={() => props.viewExport(item.hashed)}
                    />
                  )}
                />
              )
              updatedItem.created_by = `${item.created.user.last_name}, ${item.created.user.first_name}`
              updatedItem.modified = item.modified.timestamp
              updatedItem.edit = (
                <SpecialTooltip
                  text="Your account doesn't have credits and/or permission to export audiences. Please use the chat to upgrade your account."
                  condition={props.needsUpgrade}
                >
                  <Button
                    text="Edit"
                    testId="edit"
                    disabled={
                      !props.filtersHaveUpdated ||
                      !item.editable ||
                      props.userData.needsUpgrade
                    }
                    size="medium"
                    variant="secondary"
                    icon={BiEdit}
                    onClick={() => props.editExport(item.hashed)}
                    iconProps={{ style: { marginRight: 0 } }}
                  />
                </SpecialTooltip>
              )
              updatedItem.run = (
                <SpecialTooltip
                  text="Your account doesn't have credits and/or permission to export audiences. Please use the chat to upgrade your account."
                  condition={props.needsUpgrade}
                >
                  <Button
                    text={<PlayButton />}
                    testId="runExport"
                    disabled={
                      !props.filtersHaveUpdated || props.userData.needsUpgrade
                    }
                    size="medium"
                    variant="primary"
                    onClick={() => props.runExport(item.hashed)}
                    textStyle={{ fontWeight: 'bold' }}
                  />
                </SpecialTooltip>
              )

              if (item.hashed in props.updatedDTCache) {
                const { size_exportable, size_creditable } = props.updatedDTCache[
                  item.hashed
                ]
                updatedItem.records_and_credits = (
                  <div style={{ fontSize: '0.75rem' }}>
                    <div>{size_exportable.toLocaleString()} records</div>
                    <div>{size_creditable.toLocaleString()} credits used if run</div>
                  </div>
                )
              } else {
                updatedItem.records_and_credits = (
                  <SpecialTooltip
                    text="Your account doesn't have credits and/or permission to export audiences. Please use the chat to upgrade your account."
                    condition={props.needsUpgrade}
                  >
                    <LoadButton
                      disabled={!props.filtersHaveUpdated || props.needsUpgrade}
                      loadingText={'Calculating'}
                      data-cy="calculating"
                      text="Calculate"
                      size="medium"
                      variant="secondary"
                      onClick={() => props.calculate(item.hashed)}
                      textStyle={{ fontWeight: 'bold' }}
                    />
                  </SpecialTooltip>
                )
              }

              return updatedItem
            })
            return exportsTableData
          }}
        />
      </div>
    </Accordion>)
  );
}

const LeftSideSummary = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 1rem;
`

const ControlDiv = styled.div`
  display: flex;
  flex: 3;
  gap: 0.75rem;
  align-items: center;
`
