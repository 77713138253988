import Select from '@mui/material/Select'
import styled from 'styled-components'
import withStyles from '@mui/styles/withStyles'

const StyledSelect = withStyles({
  select: {
    padding: '7px',
  },
})(Select)

const AutocompleteLabel = styled.div``

export { StyledSelect, AutocompleteLabel }
