import Checkbox from 'components/Checkbox'
import SimpleInput from 'components/SimpleInput'
import Button from 'components/Button'
import StandardDialog from 'components/StandardDialog'
import SimpleAutoComplete from 'components/SimpleAutoComplete'
import { FormControlLabel } from '@mui/material'
import { Account } from 'types'
import NetWiseClient from 'api/client'

type Props = {
  user: {
    first_name: string
    last_name: string
    email: string
    account: number
    account_admin: boolean
  }
  accounts?: Array<Account>
  title: string
  isOpen: boolean
  type: 'Create' | 'Update'
  errorMessage?: string
  onChange: (x: any) => void
  action: ({ resetPassword }: { resetPassword?: boolean }) => void
  client: NetWiseClient
  closeDialog: () => void
}

export default function UserDialog(props: Props) {
  const Content = (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '35rem',
          margin: '1rem',
          rowGap: '1rem',
        }}
      >
        <SimpleInput
          data-cy="userDialogFirstName"
          onChange={(e: any) => props.onChange({ first_name: e.target.value })}
          defaultValue={props.user.first_name}
          label="First Name"
        />
        <SimpleInput
          data-cy="userDialogLastName"
          onChange={(e: any) => props.onChange({ last_name: e.target.value })}
          defaultValue={props.user.last_name}
          label="Last Name"
        />
        <SimpleInput
          data-cy="userDialogEmail"
          onChange={(e: any) => props.onChange({ email: e.target.value })}
          defaultValue={props.user.email}
          label="Email"
        />
        <SimpleAutoComplete
          disableClearable={true}
          label="Account"
          disabled={props.type === 'Create'}
          value={props.user.account}
          onChange={(value) => props.onChange({ account: value })}
          client={props.client}
          optionsUrl={props.client.endpoints.accountsAPI}
          valueField="id"
          labelField="name"
          data-cy="userDialogAccount"
        />
      </div>
      <div>
        <FormControlLabel
          labelPlacement="start"
          control={
            <div style={{ paddingLeft: '0.5rem' }}>
              <Checkbox
                data-cy="userDialogCheckbox"
                onClick={() =>
                  props.onChange({ account_admin: !props.user.account_admin })
                }
                status={props.user.account_admin}
              />
            </div>
          }
          label="Admin Account"
        />
      </div>
    </div>
  )

  return (
    <div style={{ display: 'flex' }}>
      <StandardDialog
        isOpen={props.isOpen}
        handleClose={props.closeDialog}
        title={props.title}
        Content={Content}
        ActionButtons={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              columnGap: '0.5rem',
              flexGrow: 1,
            }}
          >
            <div
              style={{
                maxWidth: '30rem',
                color: 'red',
                paddingLeft: '1.5rem',
              }}
            >
              {props.errorMessage}
            </div>
            <Button
              data-cy="userDialogButton"
              text={props.type}
              variant="primary"
              onClick={props.action}
            />
          </div>
        }
      />
    </div>
  )
}
