import { useRef, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import AudienceDropdowns from './LeftNav/AudienceDropdowns'
import ApplyFooter from './ApplyFooter'
import { useMediaSize } from '../../../context/mediaQuery-context'

export default function LeftNav({
  selectedFilters,
  filters,
  fields,
  updateFilter,
  resetCounter,
  count,
  sqlIsVerified,
  clearChanges,
  dispatch,
}: any) {
  const [width, setWidth] = useState(0)
  const navRef = useRef<HTMLDivElement>(null)
  const { smallScreen, verySmallScreen } = useMediaSize()
  const offset = smallScreen ? 209 : 136

  useEffect(() => {
    if (!!navRef.current) {
      setWidth(navRef.current.offsetWidth)
    }
  }, [navRef?.current?.offsetWidth, setWidth])

  return (
    <Grid
      item
      lg={3}
      sm={4}
      xs={12}
      style={{
        maxHeight: verySmallScreen ? 'none' : `calc(100vh - ${offset}px)`,
        overflowY: verySmallScreen ? 'visible' : 'scroll',
        overflowX: 'hidden',
        paddingBottom: '85px',
      }}
    >
      <div ref={navRef}>
        <AudienceDropdowns
          selectedFilters={selectedFilters}
          filters={filters}
          fields={fields}
          updateFilter={updateFilter}
          sqlIsVerified={sqlIsVerified}
          dispatch={dispatch}
        />
      </div>
      <ApplyFooter
        resetCounter={resetCounter}
        count={count}
        width={width}
        sqlIsVerified={sqlIsVerified}
        sql_editor={selectedFilters.sql_editor}
        clearChanges={clearChanges}
      />
    </Grid>
  )
}
