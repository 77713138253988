import Checkbox from 'components/Checkbox'
import StandardAutocomplete from '../../../../components/StandardAutocomplete'
import { toTitleCase } from '../../../../utils/caseHelpers'
import InputSubtitle from './InputSubtitle'
import { CustomFields } from '../adminTypes'
import { useEffect, useState } from 'react'
import Button from 'components/Button'
import ep from 'api/endpoints'
import { useAuth } from 'context/auth-context'

interface Props {
  customFields: CustomFields
  updateInfo: (update: Record<'customFields', any>) => void
}

type AccountType = {
  id: number
  name: string
}

type LayoutFilter = {
  id: number
  name: string
}

type CheckboxProps = {
  label: string
  status: boolean
  onClick: Props['updateInfo']
  text: string
  'data-cy'?: string
  disabled?: boolean
}
const CheckboxWithText: React.FC<CheckboxProps> = (props) => (
  <div>
    <Checkbox
      label={props.label}
      status={props.status}
      onClick={props.onClick}
      data-cy={props['data-cy']}
      disabled={props.disabled}
    />
    <InputSubtitle>{props.text}</InputSubtitle>
  </div>
)

const CustomInfo: React.FC<Props> = ({ customFields, updateInfo }) => {
  const { client } = useAuth()
  const [accountTypes, setAccountTypes] = useState<Array<AccountType>>([
    { id: 0, name: '' },
  ])
  const [layoutFiltersData, setLayoutFiltersData] = useState<Array<LayoutFilter>>([
    { id: 0, name: '' },
  ])

  const [layoutFilters, setLayoutFilters] = useState([
    'B2B2C Platform Layout Filter',
    'B2B Platform Layout Filter',
    'Digital Platform Layout Filter',
  ])

  const [selectedAccountType, setSelectedAccountType] = useState({ id: 0, name: '' })
  const [selectedLayoutFilters, setSelectedLayoutFilters] = useState<Array<string>>(
    []
  )

  useEffect(() => {
    async function getAccountTypes() {
      const res = await client.get({ endpoint: ep.accountsTypesAPI })

      // Added { id: 0, name: '' } as an initial account type option to avoid 
      // AutoComplete warning that caused the field to not populate correctly
      const allAccountTypes = [{ id: 0, name: '' }, ...res.responseData.results]
      setAccountTypes(allAccountTypes)

      const selected = accountTypes.filter(
        (n) => n.id === customFields?.accountTypeRefId
      )

      if (selected.length > 0) {
        setSelectedAccountType(selected[0])
      }
    }

    // Only get the account types once on initial load
    if (selectedAccountType.id === 0) {
      getAccountTypes()
    }
  }, [client, customFields?.accountTypeRefId, selectedAccountType.id])

  useEffect(() => {
    async function getLayoutFilters() {
      const res = await client.get({ endpoint: ep.layoutsLayoutFiltersAPI })
      const results: Array<LayoutFilter> = res.responseData.results
      setLayoutFiltersData(results)

      const mapped: Array<string> = results.map((n) => n.name)
      setLayoutFilters(mapped)

      const selected = layoutFiltersData
        .filter((n) =>
          customFields?.layoutFilters?.some((filter) => filter === n.id)
        )
        .map((n) => n.name)

      if (selected.length > 0) {
        setSelectedLayoutFilters(selected)
      }
    }

    getLayoutFilters()
  }, [client, customFields?.layoutFilters])

  // Wraps update function with proper key
  const wrapper = (update: Record<string, any>) => {
    updateInfo({ customFields: { ...customFields, ...update } })
  }

  const splitInputValues = (values: Array<string>) => {
    const updatedValues = values
      .map((val) => val.split(',').map((x: string) => x.trim()))
      .flat()
      .filter((x) => !!x)
    return updatedValues
  }

  return (
    <>
      <h3>Custom Field Access Settings</h3>
      <CheckboxWithText
        text="Allow account to view / export pii fields (personal emails/phones/addresses"
        label="Grant access to PII fields"
        status={customFields?.piiAccess || false}
        onClick={() => wrapper({ piiAccess: !customFields.piiAccess })}
        data-cy="grantPIIAccess"
        disabled
      />
      <CheckboxWithText
        label="Analysis Only"
        status={customFields?.analysisOnly || false}
        onClick={() => wrapper({ analysisOnly: !customFields.analysisOnly })}
        text="Restrict account to only view/export analysis fields (person/company ids and title), overrides grant_access_to_pii_fields and restrict_access_to_anonymized_fields"
        data-cy="analysisOnly"
        disabled
      />
      <CheckboxWithText
        label="Anonymized Fields Only"
        status={customFields?.anonymizedOnly || false}
        onClick={() =>
          wrapper({
            anonymizedOnly: !customFields.anonymizedOnly,
          })
        }
        text="Restrict account to only view/export anonymized fields (maid, hashed emails, person/company ids), overrides grant_access_to_pii_fields"
        data-cy="anonymizedFieldsOnly"
        disabled
      />
      <div style={{ display: 'flex' }}>
        <StandardAutocomplete
          label="Additional Fields"
          placeholder="List of fields to grant access to (takes precedence over field group access restrictions)"
          testId="additionalFields"
          freeSolo={true}
          multiple={true}
          value={customFields.additionalFields || ''}
          onChange={(_: any, values: Array<string>) => {
            const updatedValues = splitInputValues(values)
            wrapper({ additionalFields: updatedValues })
          }}
          autocompleteProps={{
              getOptionLabel: (value: string) => toTitleCase(value) || "",
              isOptionEqualToValue: (option: string, value: string) => {
              return option === value
            },
          }}
          EndComponent={
            <Button
              variant="secondary"
              size="medium"
              onClick={() => wrapper({ additionalFields: [] })}
              text="CLEAR"
              testId="additionalFieldsClear"
            />
          }
        />
      </div>
      <StandardAutocomplete
        label="Restricted Fields"
        placeholder="List of fields to deny access to (takes precedence over anything else)"
        testId="restrictedFields"
        freeSolo={true}
        multiple={true}
        value={customFields.restrictedFields || ''}
        onChange={(_: any, values: Array<string>) => {
          const updatedValues = splitInputValues(values)
          wrapper({ restrictedFields: updatedValues })
        }}
        autocompleteProps={{
          getOptionLabel: (value: string) => toTitleCase(value) || "",
          isOptionEqualToValue: (option: string, value: string) => {
            return option === value
          },
        }}
        EndComponent={
          <Button
            variant="secondary"
            size="medium"
            onClick={() => wrapper({ restrictedFields: [] })}
            text="CLEAR"
            testId="restrictedFieldsClear"
          />
        }
      />
      <StandardAutocomplete
        label="Account Type"
        testId="accountType"
        value={selectedAccountType}
        multiple={false}
        tagSuggestions={accountTypes}
        onChange={(_: any, value: AccountType) => {
          setSelectedAccountType(value)

          switch (value.name) {
            case 'analytics': {
              setSelectedLayoutFilters([])
              wrapper({ accountTypeRefId: value.id, layoutFilters: [] })
              break
            }
            case 'digital': {
              const values = ['Digital Platform Layout Filter']
              setSelectedLayoutFilters(values)
              const mapped = layoutFiltersData
                .filter((n) => values.some((filter) => filter === n.name))
                .map((n) => n.id)
              wrapper({ accountTypeRefId: value.id, layoutFilters: mapped })
              break
            }
            case 'multichannel': {
              const values = [
                'B2B2C Platform Layout Filter',
                'B2B Platform Layout Filter',
                'Digital Platform Layout Filter',
              ]
              setSelectedLayoutFilters(values)
              const mapped = layoutFiltersData
                .filter((n) => values.some((filter) => filter === n.name))
                .map((n) => n.id)
              wrapper({ accountTypeRefId: value.id, layoutFilters: mapped })
              break
            }
            case 'offline': {
              const values = ['B2B2C Platform Layout Filter']
              setSelectedLayoutFilters(values)
              const mapped = layoutFiltersData
                .filter((n) => values.some((filter) => filter === n.name))
                .map((n) => n.id)
              wrapper({ accountTypeRefId: value.id, layoutFilters: mapped })
              break
            }
          }
        }}
        autocompleteProps={{
          getOptionLabel: (value: AccountType) => toTitleCase(value?.name) || "",
          isOptionEqualToValue: (option: AccountType, value: AccountType) => {
            return option?.id === value?.id
          },
        }}
      />
      <StandardAutocomplete
        multiple
        label="Layout"
        testId="layoutFilters"
        value={selectedLayoutFilters}
        placeholder="Select layouts"
        tagSuggestions={layoutFilters}
        onChange={(_: any, values: Array<string>) => {
          setSelectedLayoutFilters(values)
          // Map string back to ids
          const mapped = layoutFiltersData
            .filter((n) => values.some((filter) => filter === n.name))
            .map((n) => n.id)
          wrapper({ layoutFilters: mapped })
        }}
        autocompleteProps={{
          getOptionLabel: (value: string) => toTitleCase(value) || "",
          isOptionEqualToValue: (option: string, value: string) => option === value,
        }}
      />
    </>
  )
}

export default CustomInfo
