// TODO: This whole thing is too complex. Need to reduce to separate components
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import {
  StyledAutocomplete,
  AutocompleteLabel,
  StyledListBox,
} from './StandardAutocomplete/Css'
import { ReactComponent as ChevronDown } from '../icons/16px/Chevron/Down.svg'
import { ReactComponent as Close } from '../icons/16px/Close.svg'
import { colors } from '../context/theme-context'
import { InputAdornment } from '@mui/material'
import styled from 'styled-components'
import ChipBasket from './ChipFilter/ChipBasket'
import { useEffect, useState } from 'react'
import { useAuth } from '../context/auth-context'
import { debounce } from '../utils/debounce'

const StyledChip = styled(Chip)`
  border-radius: 0.25rem;
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
`

const StyledClose = styled(Close)`
  rect:nth-child(1) {
    stroke: ${colors.gray5};
  }
  rect:nth-child(n + 2) {
    fill: ${colors.gray7};
  }
`

const listStyle = {
  borderRadius: '0.25rem',
  backgroundColor: colors.gray1,
  border: `1px solid ${colors.gray6}`,
  fontSize: '0.875rem',
  color: colors.gray8,
}

interface StandardAutocompleteRemoteI {
  label?: any
  highlightColor?: any
  value?: any
  onChange?: any
  variant?: any
  disabled?: any
  multiple?: any
  freeSolo?: any
  placeholder?: any
  LeftAdornment?: any
  RightAdornment?: any
  labelProps?: any
  autocompleteProps?: any
  url?: any
  additionalAPIparams?: any
  valueFilterField?: any
  filterHandler?: any
  style?: any
}

const StandardAutocompleteRemote: React.FC<StandardAutocompleteRemoteI> = ({
  label = null,
  highlightColor = null,
  value = null,
  onChange = (_: any, value: any = null) => value,
  variant = 'outlined',
  disabled = false,
  multiple = true,
  freeSolo = false,
  placeholder = '',
  LeftAdornment = null,
  RightAdornment = null,
  labelProps = {},
  autocompleteProps = {},
  url,
  additionalAPIparams = {},
  // the field used for filtering remoteTagSuggestions
  // when value is passed
  valueFilterField = 'name',
  // custom hanlder when remoteTagSuggestions are of different
  // structure then the value
  filterHandler,
  ...rest
}) => {
  const { client } = useAuth()
  const chipBasket = multiple
  const [basketValues, setBasketValues] = useState(value || [])
  const [filterText, setFilterText] = useState('')
  const [remoteTagSuggestions, setRemoteTagSuggestions] = useState([])
  const [initialized, setInitialized] = useState(false)
  const debouncedSearch = debounce(setFilterText, 200)

  useEffect(() => {
    function _setSuggestions(suggestions: any) {
      setRemoteTagSuggestions(suggestions)
    }

    async function getData(getURL: any) {
      try {
        const { responseData } = await client.get({
          endpoint: getURL,
          data: {
            size: 50,
            filter: filterText || '',
            ...additionalAPIparams,
          },
        })

        const { results } = responseData
        const updatedResults = filterHandler ? filterHandler(results) : results
        if (
          value &&
          !!value[valueFilterField] &&
          !updatedResults
            .map((o: any) => o[valueFilterField])
            .includes(value[valueFilterField])
        ) {
          updatedResults.unshift(value)
        }
        _setSuggestions(updatedResults)
      } catch (e) {
        console.log(e)
      }
    }

    getData(url)
  }, [
    filterHandler,
    filterText,
    url,
    client,
    value,
    valueFilterField,
    // additionalAPIparams, --Uncommenting this will cause the layout api to fire several times in succession
  ])

  useEffect(() => {
    setBasketValues(value || [])
  }, [value])

  const moreInputProps = {
    value: null,
  }

  if (!multiple) {
    moreInputProps.value = value
  }

  useEffect(() => {
    setBasketValues(value || [])
  }, [value])

  function renderTags(values: any, getTagProps: any) {
    if (!chipBasket) {
      const chips = values?.map((value: any, i: any) => (
        <StyledChip
          deleteIcon={<StyledClose />}
          key={i}
          disabled={true}
          value={value}
          label={value}
          {...getTagProps({ index: i })}
        />
      ))
      return chips
    }
  }

  return (
    <div style={{ flex: 1 }} {...rest}>
      {label && <AutocompleteLabel {...labelProps}>{label}</AutocompleteLabel>}
      <StyledAutocomplete
        freeSolo={freeSolo}
        multiple={multiple}
        value={multiple ? value || [] : value}
        size="small"
        disabled={disabled}
        disableCloseOnSelect={false}
        disableClearable
        autoHighlight={true}
        options={remoteTagSuggestions}
        onChange={(e: any, values: unknown) => {
          if (onChange) {
            setBasketValues(values)
            onChange(e, values)
          }
          debouncedSearch('')
        }}
        onInputChange={(_: React.SyntheticEvent, inputValue: string) => {
          if (initialized) {
            debouncedSearch(inputValue || '')
          } else if (!!inputValue) {
            setInitialized(true)
          }
        }}
        popupIcon={<ChevronDown />}
        noOptionsText={
          <span style={{ ...listStyle, fontSize: '16px', padding: '1rem' }}>
            No Options
          </span>
        }
        renderInput={(params: any) => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start" style={{ marginRight: 0 }}>
                      {LeftAdornment ? (
                        <LeftAdornment style={{ marginLeft: '0.5rem' }} />
                      ) : (
                        <div />
                      )}
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <div style={{ marginLeft: '1rem' }}>
                        {RightAdornment ? <RightAdornment /> : ''}
                      </div>
                    </InputAdornment>
                    {params.InputProps.endAdornment}
                  </>
                ),
                style: { padding: '2px 2px' },
              }}
              inputProps={{
                style: { padding: '0.5rem' },
                ...moreInputProps,
                ...params.inputProps,
              }}
              variant={variant}
              placeholder={placeholder}
            />
          )
        }}
        renderTags={renderTags}
        PaperComponent={(params: any) => (
          <StyledListBox highlightColor={highlightColor} {...params} />
        )}
        ListboxProps={{ style: listStyle }}
        {...autocompleteProps}
      />
      {basketValues.length > 0 && multiple && (
        <div style={{ paddingTop: '0.5rem' }}>
          <ChipBasket
            selectedOptions={basketValues}
            handleChipDelete={(option: any) => {
              if (!disabled) {
                const filteredValues = basketValues.filter(
                  (item: any) => item !== option
                )
                setBasketValues(filteredValues)
                onChange('_', filteredValues)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}

export default StandardAutocompleteRemote
