import React from 'react'
import Button from 'components/Button'
import SimpleInput from 'components/SimpleInput'
import TopSlider from 'components/TopSlider'
import { useAuth } from 'context/auth-context'
import { useHistory } from 'react-router-dom'
import { LinearProgress } from '@mui/material'

export default function PersonSearch() {
  const history = useHistory()
  const { client } = useAuth()

  const [isOpen, setIsOpen] = React.useState(false)
  const [text, setText] = React.useState('')
  const [isFetching, setIsFetching] = React.useState<undefined | boolean>()

  type Accounts = Array<{ id: number; name: string }>
  const [accountOptions, setAccountOptions] = React.useState<Accounts>([])

  React.useEffect(() => {
    // When the PersonSearch is closed, we'll want to reset all of the relevant state
    if (!isOpen) {
      setText('')
      setAccountOptions([])
      setIsFetching(undefined)
    }
  }, [isOpen])

  async function searchUsers() {
    setIsFetching(true)
    const { responseData } = await client.get({
      endpoint: client.endpoints.usersAPI,
      data: { filter: text },
    })
    setIsFetching(false)
    const { results } = responseData

    let accounts: Accounts = []
    results.forEach((result: any) => {
      const { id, name } = result.account
      if (accounts.map((account) => account.id).includes(id)) return
      accounts.push({ id, name })
    })

    setAccountOptions(accounts)
  }

  const SearchResults = () => {
    if (typeof isFetching === 'undefined') return null
    if (accountOptions.length > 0) {
      return (
        <div>
          <div>
            User may be found in following account{accountOptions.length > 1 && 's'}:
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0.5rem 0rem',
              rowGap: '0.25rem',
              fontWeight: 400,
            }}
          >
            {accountOptions.map((account) => (
              <Button
                key={account.id}
                text={account.name}
                onClick={() =>
                  history.push(`admin/accounts/detail/${account.id}?tab=user`)
                }
                variant="secondary"
                size="small"
                data-cy="accountButton"
              />
            ))}
          </div>
        </div>
      )
    } else {
      return <div>No results found</div>
    }
  }

  const Slider = (
    <TopSlider isVisible={isOpen}>
      <>
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ padding: '0.25rem' }}>
              <Button
                size="small"
                text="close"
                variant="delete"
                onClick={() => setIsOpen(false)}
                data-cy="closePersonLookup"
              />
            </div>
          </div>
          <form
            style={{
              padding: '2rem',
              display: 'flex',
              rowGap: '0.25rem',
              flexDirection: 'column',
              minWidth: '500px',
            }}
          >
            <div>
              <SimpleInput
                autoFocus={true}
                label="Person Lookup"
                defaultValue={''}
                placeholder="First / Last / Email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setText(e.target.value.trim())
                }
                debounced={true}
                data-cy="personLookup"
              />
            </div>
            <div>
              <Button
                size="medium"
                text="Search"
                variant="secondary"
                onClick={searchUsers}
                data-cy="searchPersonLookup"
              />
            </div>
            <div>{isFetching ? <LinearProgress /> : <SearchResults />}</div>
          </form>
        </div>
      </>
    </TopSlider>
  )
  return (
    <>
      <Button
        size="medium"
        variant={'secondary'}
        text={'Open Person Lookup'}
        onClick={() => setIsOpen(!isOpen)}
        data-cy="openPersonLookup"
      />
      {Slider}
    </>
  )
}
