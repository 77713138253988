import Tooltip from '@mui/material/Tooltip'
import withStyles from '@mui/styles/withStyles'
import { colors, typography } from '../../context/theme-context/'

const { medium } = typography.bodyRegular

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: colors.gray10,
    fontSize: medium.fontSize,
    fontWeight: medium.fontWeight,
    lineHeight: medium.lineHeight,
    color: colors.gray2,
    padding: '0.375rem 0.5rem',
    borderRadius: '0.25rem',
  },
  arrow: {
    color: colors.gray10,
  },
})(Tooltip)

export { StyledTooltip }
