import React, { useEffect, useState, useRef, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import { useAuth } from 'context/auth-context'
import { useSnackbar } from 'context/snackbar-context'
import SaveAudienceModal from './NameAudience/SaveAudienceModal'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import colors from 'context/theme-context/colors'
import Counts from 'components/TitleBar/Counts'
import CustomButton from 'components/Button'
import ExportAudienceDialog from './Dashboard/DashboardTabs/ExportsTab/ExportAudienceDialog'
import QuickExportDialog from 'components/QuickExportDialog'
import UpdateWarningTooltip from 'components/UpdateWarningTooltip'
import Tooltip from 'components/Tooltip'
import ExportsLink from './NameAudience/ExportsLink'
import styled from 'styled-components'
import Button from 'components/Button'
import { BiPrinter } from 'react-icons/bi'
import { Export, MapEntry } from 'types'
import { useMediaSize } from 'context/mediaQuery-context'
import BaseExportDialog from 'components/BaseExportDialog'
import tm from 'analytics/TagManager'

export interface DialogExport extends Export {
  runHandler: () => void
  icon: string
}

interface Props {
  filtersHaveUpdated: boolean
  updateFilter: (payload: any) => void
  selectedFilters: any
  appliedFilters: any
  audienceInfo: any
  dispatch: any
  audienceSize: any
  printPdf: any
  metricsDataReady: boolean
  countsDataReady: boolean
  allDataMode: boolean
}

const NameAudience: React.FC<Props> = ({
  filtersHaveUpdated,
  updateFilter,
  selectedFilters,
  appliedFilters,
  audienceInfo,
  dispatch,
  audienceSize,
  printPdf,
  metricsDataReady,
  countsDataReady,
  allDataMode,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { setSnackbarMessage } = useSnackbar()
  const { client, userData, socketHandler: sockets } = useAuth()
  const history = useHistory()
  const location = useLocation()
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false)
  const latestRequest = useRef<Array<string> | undefined>([])
  const { hash: audienceHash } = useParams<{ hash: string | undefined }>()

  // Use two states for filtering functionality:
  //   one for filtered items (displayedExports),
  //   and one for all items (exports)
  const [displayedExports, setDisplayedExports] = useState([])
  const [isExportOpen, setIsExportOpen] = useState(false)
  const [isQuickExportOpen, setIsQuickExportOpen] = useState(false)
  const [chosenExport, setChosenExport] = useState<Export | any>({})
  const [updatedDTCache, setUpdatedDTCache] = useState<Record<string, any>>({})
  const [searchText, setSearchText] = useState()
  const { verySmallScreen } = useMediaSize()
  const {
    audiencesAPI,
    audiencesIdAPI,
    exportSizeAPI,
    exportsIdAPI,
    exportsTableAPI,
  } = client.endpoints

  const { credits, account } = userData
  const isCta = credits === 0 && account.stage === 'freemium'

  useEffect(() => {
    setUpdatedDTCache({})
  }, [filtersHaveUpdated])

  React.useEffect(() => {
    const { contacts, companies } = audienceSize
    if (typeof contacts !== 'undefined' && typeof companies !== 'undefined') {
      dispatch({ payload: { countsDataReady: true } })
    }
  }, [audienceSize, dispatch])

  const extractResults: MapEntry['callback'] = ({ results }) => {
    const {
      size,
      size_credited,
      size_uncredited,
      size_creditable,
      size_exportable,
      export_hash,
    } = results

    setUpdatedDTCache((state) => ({
      ...state,
      [export_hash]: {
        size: size,
        size_credited: size_credited,
        size_uncredited: size_uncredited,
        size_creditable: size_creditable,
        size_exportable: size_exportable,
      },
    }))
  }

  if (sockets) {
    sockets.taskSocket?.addMapback({
      id: 'nameAudience',
      socketRequestType: 'export_size',
      callback: extractResults,
    })
  }

  useEffect(() => {
    async function getExports() {
      try {
        const { responseData } = await client.get({
          endpoint: exportsTableAPI,
          data: {
            size: 50,
            filter: searchText || '',
            sort: '-favorite,-modified',
            include_autosave: false,
          },
        })

        const { results: resultingExports } = responseData
        const exportData: Array<DialogExport> = resultingExports.map((item: any) => {
          return {
            ...item,
            runHandler: async () => {
              setIsExportOpen(true)
              setIsExportDialogOpen(false)

              const { responseData } = await client.get({
                endpoint: exportsIdAPI(item.hashed),
              })

              setChosenExport(responseData.results)
            },
            icon: item.data.image ? item.data.image.split('.')[0] : null,
          }
        })

        // Include Quick Export in the search
        const regex = new RegExp(searchText || '', 'i')
        const quickExportTitle = 'Quick Export'

        let allExports = exportData as any
        if (quickExportTitle.match(regex) || searchText == null) {
          const quickExport = {
            name: 'Quick Export',
            description:
              'Use Quick Export to export your audience fast! You can change the layout and all settings before exporting.',
            runHandler: () => {
              setIsExportDialogOpen(false)
              setIsQuickExportOpen(true)
            },
            icon: 'quick',
            hashed: null,
          }
          allExports = [quickExport, ...allExports]
        }
        setDisplayedExports(allExports)
      } catch (e) {
        console.log('Could not retrieve data at this time.')
      }
    }
    getExports()
  }, [setDisplayedExports, client, exportsTableAPI, searchText])

  const submissionCallback = () => {
    const url = location.pathname + '?tab=exports&focus=recent'
    const action = () => history.push(url)
    const LinkToRecentExports = <ExportsLink action={action} />
    setSnackbarMessage({ text: LinkToRecentExports })
  }

  async function saveAudience(audience_name: any, audience_description: any) {
    try {
      const { responseData } = await client.post({
        endpoint: audiencesAPI,
        data: {
          ...selectedFilters,
          audience_name,
          audience_description,
        },
      })
      const { results: responseAudience } = responseData
      setDialogOpen(false)
      dispatch({
        payload: {
          savedFilters: selectedFilters,
          saveIsCurrent: true,
        },
      })
      setSnackbarMessage({ text: 'Audience Saved' })
      history.push('/audiences/' + responseAudience.hashed)
    } catch (e) {
      console.log('Could not retrieve data at this time.')
    }
  }

  async function updateAudience(
    audience_name: string,
    audience_description: string,
    audienceHash: string,
    audience_id: string
  ) {
    try {
      const { responseData } = await client.put({
        endpoint: audiencesIdAPI(audienceHash),
        data: {
          ...selectedFilters,
          audience_name,
          audience_description,
          audience_id,
        },
      })
      setDialogOpen(false)
      dispatch({
        payload: {
          savedFilters: selectedFilters,
          saveIsCurrent: true,
        },
      })
      setSnackbarMessage({ text: 'Audience Updated' })
      const { results } = responseData
      history.push('/audiences/' + results.hashed)
    } catch (e) {
      console.log('Could not retrieve data at this time.')
    }
  }

  const calculate = useCallback(
    async function (hashed: any) {
      try {
        await client.post({
          endpoint: exportSizeAPI,
          data: {
            exports: [hashed],
            additional_audience: appliedFilters,
            channel_name: sockets?.taskSocket?.channelName,
          },
        })
      } catch (e) {
        console.log('Could not retrieve export size', e)
      }
    },
    [appliedFilters, sockets?.taskSocket?.channelName, exportSizeAPI, client]
  )

  let titleText = userData.needsUpgrade
    ? "Your account doesn't have credits and/or permission to export audiences. Please use the chat to upgrade your account."
    : 'Changes made but not applied. Hit "u" key or click "Apply Changes" button to apply updates.'

  const parser = new URLSearchParams(location.search)
  const tab = parser.get('tab') || 'metrics'

  const ExportButton = isCta ? (
    <div id="primary_upgrade_button">
      <CustomButton
        onClick={() => tm.captureCustomEvent('intercom_upgrade')}
        variant="primary"
        text="Upgrade Now"
        disabled={false}
      />
    </div>
  ) : !audienceSize?.contacts || !filtersHaveUpdated || userData.needsUpgrade ? (
    <Tooltip title={titleText}>
      <div>
        <CustomButton
          onClick={() => null}
          variant="primary"
          text="Export Audience"
          disabled={true}
        />
      </div>
    </Tooltip>
  ) : (
    <CustomButton
      variant="primary"
      text="Export Audience"
      disabled={tab === 'metrics' && !countsDataReady}
      onClick={() => setIsExportDialogOpen(true)}
      testId="exportAudienceButton"
    />
  )

  const index = chosenExport?.hashed
  const creditsToBeUsed = updatedDTCache[index]?.size_creditable

  return (
    <Grid
      container
      item
      alignItems="center"
      style={{
        zIndex: 1, // TODO: This may be hiding layout issues. Possibly caused by a negative margin from the Grid component
        borderBottom: `1px solid ${colors.gray5}`,
        backgroundColor: colors.gray1,
        padding: '0.5rem 1rem',
        marginBottom: '0.5rem',
      }}
    >
      <Grid item md={6} xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }} id="save">
          <div
            onClick={() => setDialogOpen(true)}
            style={{
              border: 'none',
              fontWeight: 500,
              fontSize: '1.375rem',
              lineHeight: '1.5rem',
              color: `${colors.blue9}`,
            }}
            id="audience_name"
            data-cy="currentAudienceName"
          >
            {audienceInfo.audience_name || 'Untitled Audience'}
          </div>

          <div style={{ paddingLeft: '0.75rem' }}>
            <CustomButton
              variant="secondary"
              size="medium"
              icon="save"
              text="Save"
              onClick={() => setDialogOpen(true)}
              id="save"
              testId="saveAudienceName"
            />
          </div>
        </div>
      </Grid>

      <Grid item md={6} xs={12}>
        <AudienceCountDiv>
          <AudienceCountDiv id="audience_size">
            {!filtersHaveUpdated && <UpdateWarningTooltip />}
            <div>
              <Counts
                filtersHaveUpdated={filtersHaveUpdated}
                dataReady={countsDataReady}
                countNumber={audienceSize?.contacts}
                countTitle={'Contacts'}
                data-cy="contactsCount"
              />
            </div>
            <div>
              <Counts
                filtersHaveUpdated={filtersHaveUpdated}
                dataReady={countsDataReady}
                countNumber={audienceSize?.companies}
                countTitle={'Companies'}
                data-cy="companiesCount"
              />
            </div>
          </AudienceCountDiv>
          <div style={{ display: 'flex', columnGap: '0.5rem' }}>
            <Tooltip
              title={
                tab === 'metrics'
                  ? 'Export Audience as PDF'
                  : 'Export Audience as PDF (Click on metrics tab to enable)'
              }
            >
              <div>
                <Button
                  id="export_audience_pdf"
                  testId="printAudiencePdf"
                  icon={() => <BiPrinter size="1.75rem" />}
                  iconProps={{ style: { margin: 0, padding: 0 } }}
                  variant="secondary"
                  onClick={() => {
                    printPdf(audienceInfo.audience_name)
                    setSnackbarMessage({ text: 'Printing to PDF' })
                  }}
                  style={{ padding: '0.75rem 1rem' }}
                  disabled={
                    !(metricsDataReady && countsDataReady && filtersHaveUpdated)
                  }
                />
              </div>
            </Tooltip>
            <div id="export_button" data-cy="exportAudience">
              {verySmallScreen && !filtersHaveUpdated ? null : ExportButton}
            </div>
          </div>
        </AudienceCountDiv>
      </Grid>
      <SaveAudienceModal
        audienceInfo={audienceInfo}
        dispatch={dispatch}
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        saveAudience={saveAudience}
        updateAudience={updateAudience}
        privacy={selectedFilters.privacy}
        updateFilter={updateFilter}
      />

      <ExportAudienceDialog
        isOpen={isExportDialogOpen}
        handleClose={() => setIsExportDialogOpen(false)}
        exports={displayedExports}
        calculate={calculate}
        userCredits={credits}
        updatedDTCache={updatedDTCache}
        searchText={searchText}
        setSearchText={setSearchText}
      />

      <QuickExportDialog
        audienceInfo={audienceInfo}
        isOpen={isQuickExportOpen}
        handleExit={() => setIsQuickExportOpen(false)}
        appliedFilters={appliedFilters}
        dispatch={dispatch}
        submissionCallback={submissionCallback}
      />

      <BaseExportDialog
        audienceInfo={audienceInfo}
        isOpen={isExportOpen}
        chosenExport={chosenExport}
        handleExit={() => setIsExportOpen(false)}
        appliedFilters={appliedFilters}
        setRenderExportAudience={() =>
          dispatch?.({ payload: { renderExportAudience: true } })
        }
        submissionCallback={submissionCallback}
        dialogType="run"
        credits={userData.credits}
        creditsToBeUsed={creditsToBeUsed}
      />
    </Grid>
  )
}

const AudienceCountDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  column-gap: 1.5rem;
  align-items: center;
  @media (max-width: 800px) {
    justify-content: space-between;
  }
`

export default NameAudience
