import StandardAutocompleteRemote from '../../../../components/StandardAutocompleteRemote'
import Grid from '@mui/material/Grid'
import Button from '../../../../components/Button'
import { ReactComponent as Search } from '../../../../icons/16px/Search.svg'
import { ReactComponent as Intersect } from '../../../../icons/16px/Intersect.svg'
import { ReactComponent as Union } from '../../../../icons/16px/Union.svg'
import { ReactComponent as Exclude } from '../../../../icons/16px/Exclude.svg'
import { useAuth } from '../../../../context/auth-context'
import StandardAutocomplete from '../../../../components/StandardAutocomplete'

export default function Layer({ audience_layer, removeLayer, updateLayer }: any) {
  const { client } = useAuth()
  const { audiencesAPI } = client.endpoints
  const { audience, stacked_type } = audience_layer

  const stackOptions = [
    { raw: 'And in', text: 'And In (Intersection)', Icon: Intersect },
    { raw: 'Or in', text: 'Or In (Union)', Icon: Union },
    { raw: 'And not in', text: 'And Not In (Exclude)', Icon: Exclude },
  ]

  const audienceStackOptionValue = stackOptions.filter(
    (option) => option.raw === stacked_type
  )[0]

  let LayerIcon
  switch (audienceStackOptionValue.text) {
    case 'And In (Intersection)':
      LayerIcon = Intersect
      break
    case 'Or In (Union)':
      LayerIcon = Union
      break
    case 'And Not In (Exclude)':
      LayerIcon = Exclude
      break
    default:
      LayerIcon = Intersect
      break
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div style={{ marginBottom: '1rem' }}>
          <StandardAutocompleteRemote
            url={audiencesAPI}
            additionalAPIparams={{
              dropdown_view: true,
              include_autosave: false,
              include_deleted: false,
              sort: '-modified',
            }}
            LeftAdornment={Search}
            placeholder="Please Select Audience Layer"
            valueFilterField={'name'}
            value={audience}
            multiple={false}
            autocompleteProps={{
              getOptionLabel: (x: any) => {
                if (Array.isArray(x) || (x?.name || '') === '') return ''
                return x.name + (x.deleted ? ' (DELETED)' : '')
              },
              isOptionEqualToValue: (option: any, value: any) => {
                if (Array.isArray(value) || !value) return ''
                const result = option?.id === value.id
                return result
              },
              getOptionDisabled: (option: any) => {
                return !!option?.deleted
              },
            }}
            onChange={(_: any, value: any) => {
              updateLayer({ audience: value })
            }}
            data-cy="selectAudienceLayer"
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <StandardAutocomplete
          LeftAdornment={LayerIcon}
          value={audienceStackOptionValue}
          tagSuggestions={stackOptions}
          multiple={false}
          autocompleteProps={{
            getOptionLabel: (option: any) => {
              return option.text || ""
            },
            isOptionEqualToValue: (option: any, value: any) => {
              const result = option.text === value.text
              return result
            },
            renderOption: (props: object, option: any, state: object, ownerState: object) => {
              const { Icon, text, raw } = option
              return (<li {...props} key={raw}><Icon />&nbsp;&nbsp;&nbsp;{text}</li>)
            },
          }}
          onChange={(_: any, value: any) => {
            updateLayer({
              stacked_type: value.raw,
              audience: audience,
            })
          }}
          testId="selectJoinType"
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="warning"
          text="Remove"
          onClick={removeLayer}
          style={{ display: 'flex', padding: 'calc(0.25rem + 2px) 0.25rem' }}
          testId="removeLayer"
        />
      </Grid>
    </Grid>
  )
}
