import React from 'react'
import styled from 'styled-components'
import UserDetail from '../UserDetail'
import AccountDetail from './AccountDetail'
import AccountNotes from './AccountNotes'
import AudienceSaveHistory from './AudienceSaveHistory'
import FileExportHistory from './FileExportHistory'
import { colors } from 'context/theme-context'
import { AppBar, Grid, Tabs } from '@mui/material'
import { StyledTab } from '../Audiences/Dashboard/DashboardTabs'
import TabPanel from '../Audiences/Dashboard/DashboardTabs/TabPanel'
import { useHistory, useLocation, useParams } from 'react-router-dom'

export default function Account() {
  const [currentTab, setCurrentTab] = React.useState(0)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const accountId = Number(id)

  React.useEffect(() => {
    const parser = new URLSearchParams(location.search)
    const tab = parser.get('tab')
    switch (tab) {
      case 'account':
        setCurrentTab(0)
        break
      case 'user':
        setCurrentTab(1)
        break
      case 'history':
        setCurrentTab(2)
        break
      case 'exports':
        setCurrentTab(3)
        break
      default:
        break
    }
  }, [location])

  function changeTab(_: any, idx: number) {
    const tabs = ['account', 'user', 'history', 'exports']
    const newEntry = location.pathname
    history.push(newEntry + `?tab=${tabs[idx]}`)
    setCurrentTab(idx)
  }

  return (
    <AdminContainer>
      <Grid item xs={12} style={{ minHeight: '600px' }}>
        <AppBar
          position="static"
          color="default"
          variant="outlined"
          style={{ border: 'none' }}
        >
          <Tabs
            style={{
              backgroundColor: `${colors.gray3}`,
              border: `1px solid ${colors.gray5}`,
              borderBottom: 'none',
              borderTopRightRadius: `0.5rem`,
              borderTopLeftRadius: `0.5rem`,
            }}
            value={currentTab}
            onChange={changeTab}
            TabIndicatorProps={{
              style: {
                height: '0.25rem',
                top: '0px',
                backgroundColor: colors.blue8,
              },
            }}
          >
            <StyledTab label="Account Detail" data-cy="accountDetailTab" />
            <StyledTab label="User Detail" data-cy="userDetailTab" />
            <StyledTab
              label="Audience Save History"
              data-cy="audienceSaveHistoryTab"
            />
            <StyledTab label="File Export History" data-cy="fileExportHistoryTab" />
          </Tabs>
        </AppBar>
        <TabPanel currentTab={currentTab} idx={0}>
          <AccountDetail />
        </TabPanel>
        <TabPanel currentTab={currentTab} idx={1}>
          <UserDetail />
        </TabPanel>
        <TabPanel currentTab={currentTab} idx={2}>
          <AudienceSaveHistory />
        </TabPanel>
        <TabPanel currentTab={currentTab} idx={3}>
          <FileExportHistory />
        </TabPanel>
        <AccountNotes accountId={accountId} />
      </Grid>
    </AdminContainer>
  )
}

const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0rem;
  padding: 1rem;
  overflow-y: scroll;
  width: 100%;
`
