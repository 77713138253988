import styled, { css } from 'styled-components'
import { colors } from '../../../../context/theme-context/'

const Container = styled.div`
  padding: 0rem 1rem;
  &:hover {
    background: ${colors.gray2};
  }
  :active {
    background: ${colors.gray3};
  }
`

const InnerContainer = styled.div<{ selected: boolean; textColor: string }>`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  height: 4rem;
  padding: 1.25rem 0rem;
  color: ${(props) => props.textColor || colors.blue9};
  display: flex;
  align-items: center;
  text-decoration: none;
  ${(props) =>
    props.selected &&
    css`
      border-bottom: 2px solid ${colors.blue6};
      padding-bottom: 1.125rem;
    `}
`

interface NavButtonI {
  Icon?: any
  Label: any
  selected: boolean
  textColor?: string
}

const NavButton: React.FC<NavButtonI> = ({ Icon, Label, selected, textColor }) => {
  return (
    <Container>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <InnerContainer selected={selected} textColor={textColor}>
        {Icon && <Icon style={{ marginRight: '0.5rem' }} />}
        {Label}
      </InnerContainer>
    </Container>
  )
}

export default NavButton
