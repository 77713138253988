const { NODE_ENV } = process.env

const log = (...args: string[]): void => {
  if (NODE_ENV === 'production') {
    return
  }
  console.log('*DEBUG LOGGER*', args.join(''))
}

export { log }
