import styled, { css } from 'styled-components'
import { typography, colors } from '../../context/theme-context'

const { big, medium, small } = typography.bodyBold

const IconDiv = styled.span<{ medium?: boolean }>`
  display: flex;
  margin-right: 0.25rem;
  align-items: center;
  svg > path {
    fill: ${colors.gray1};
  }
  ${(props) =>
    props.medium &&
    css`
      svg {
        height: 16px;
        width: 16px;
      }
    `}
`

const StyledButton = styled.button<{
  size?: string
  variant?: string
  disabled?: boolean
}>`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: ${big.fontSize};
  font-weight: ${big.fontWeight};
  line-height: 1.25rem;
  padding: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  ${(props) =>
    props.size === 'medium' &&
    css`
      font-size: ${medium.fontSize};
      font-weight: ${medium.fontWeight};
      line-height: ${medium.lineHeight};
      padding: 0.5rem;
      svg {
        height: 0.75rem;
        width: 0.75rem;
      }
    `};
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${small.fontSize};
      font-weight: ${small.fontWeight};
      line-height: ${small.lineHeight};
      padding: calc(0.25rem);
      svg {
        height: 0.5rem;
        width: 0.5rem;
      }
    `};
  ${(props) =>
    props.variant === 'primary' &&
    css`
      background: ${colors.blue6};
      border: 1px solid ${colors.blue6};
      color: ${colors.gray1};
      &:hover {
        background: ${colors.blue7};
      }
      :active {
        background: ${colors.blue8};
      }
    `};

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      background: ${colors.blue1};
      border: 1px solid ${colors.blue2};
      color: ${colors.blue8};
      &:hover {
        background: ${colors.blue2};
        border-color: ${colors.blue3};
      }
      :active {
        background: ${colors.blue3};
        border-color: ${colors.blue4};
      }

      svg > path {
        fill: ${colors.blue9};
      }
    `};

  ${(props) =>
    props.variant === 'default' &&
    css`
      background: ${colors.gray2};
      border: 1px solid ${colors.gray5};
      color: ${colors.gray8};
      &:hover {
        border: 1px solid ${colors.gray6};
      }
      :active {
        border: 1px solid ${colors.gray7};
      }
      svg > path {
        fill: ${colors.gray8};
      }
    `};

  ${(props) =>
    props.variant === 'white' &&
    css`
      background: inherit;
      border: 1px solid ${colors.gray6};
      color: ${colors.gray11};
      &:hover {
        border: 1px solid ${colors.gray6};
      }
      :active {
        border: 1px solid ${colors.gray7};
      }
      svg > path {
        fill: ${colors.gray11};
        stroke: ${colors.gray11};
      }
    `};

  ${(props) =>
    props.variant === 'warning' &&
    css`
      background: ${colors.gray3};
      border: 1px solid ${colors.gray6};
      color: ${colors.gray8};
      svg > path {
        fill: ${colors.gray8};
      }
      &:hover {
        background: ${colors.red1};
        border: 1px solid ${colors.red3};
        color: ${colors.red7};
        svg > path {
          fill: ${colors.red7};
        }
      }
      :active {
        border: 1px solid ${colors.red8};
        color: ${colors.red8};
        svg > path {
          fill: ${colors.red8};
        }
      }
    `};

  ${(props) =>
    props.variant === 'delete' &&
    css`
      background: ${colors.gray3};
      border: 1px solid ${colors.red3};
      color: ${colors.red6};
      svg > path {
        fill: ${colors.red6};
      }
      &:hover {
        background: ${colors.red1};
        border: 1px solid ${colors.red3};
        color: ${colors.red7};
        svg > path {
          fill: ${colors.red7};
        }
      }
      :active {
        border: 1px solid ${colors.red8};
        color: ${colors.red8};
        svg > path {
          fill: ${colors.red8};
        }
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: ${colors.gray4};
      border: 1px solid ${colors.gray4};
      color: ${colors.gray7};
      pointer-events: none;
      svg > path {
        fill: ${colors.gray7};
        stroke: ${colors.gray7};
      }
    `};
`

const TextDiv = styled.div`
  font-weight: bold;
`

const ButtonStyledLink = styled.a<{
  size?: string
  variant?: string
  disabled?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: ${big.fontSize};
  font-weight: ${big.fontWeight};
  line-height: 1.25rem;
  padding: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  ${(props) =>
    props.size === 'medium' &&
    css`
      font-size: ${medium.fontSize};
      font-weight: ${medium.fontWeight};
      line-height: ${medium.lineHeight};
      padding: 0.5rem;
      svg {
        height: 0.75rem;
        width: 0.75rem;
      }
    `};
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${small.fontSize};
      font-weight: ${small.fontWeight};
      line-height: ${small.lineHeight};
      padding: calc(0.25rem);
      svg {
        height: 0.5rem;
        width: 0.5rem;
      }
    `};
  ${(props) =>
    props.variant === 'primary' &&
    css`
      background: ${colors.blue6};
      border: 1px solid ${colors.blue6};
      color: ${colors.gray1};
      &:hover {
        background: ${colors.blue7};
      }
      :active {
        background: ${colors.blue8};
      }
    `};

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      background: ${colors.blue1};
      border: 1px solid ${colors.blue2};
      color: ${colors.blue8};
      &:hover {
        background: ${colors.blue2};
        border-color: ${colors.blue3};
      }
      :active {
        background: ${colors.blue3};
        border-color: ${colors.blue4};
      }

      svg > path {
        fill: ${colors.blue9};
      }
    `};

  ${(props) =>
    props.variant === 'default' &&
    css`
      background: ${colors.gray2};
      border: 1px solid ${colors.gray5};
      color: ${colors.gray8};
      &:hover {
        border: 1px solid ${colors.gray6};
      }
      :active {
        border: 1px solid ${colors.gray7};
      }
      svg > path {
        fill: ${colors.gray8};
      }
    `};

  ${(props) =>
    props.variant === 'white' &&
    css`
      background: inherit;
      border: 1px solid ${colors.gray6};
      color: ${colors.gray11};
      &:hover {
        border: 1px solid ${colors.gray6};
      }
      :active {
        border: 1px solid ${colors.gray7};
      }
      svg > path {
        fill: ${colors.gray11};
        stroke: ${colors.gray11};
      }
    `};

  ${(props) =>
    props.variant === 'warning' &&
    css`
      background: ${colors.gray3};
      border: 1px solid ${colors.gray6};
      color: ${colors.gray8};
      svg > path {
        fill: ${colors.gray8};
      }
      &:hover {
        background: ${colors.red1};
        border: 1px solid ${colors.red3};
        color: ${colors.red7};
        svg > path {
          fill: ${colors.red7};
        }
      }
      :active {
        border: 1px solid ${colors.red8};
        color: ${colors.red8};
        svg > path {
          fill: ${colors.red8};
        }
      }
    `};

  ${(props) =>
    props.variant === 'delete' &&
    css`
      background: ${colors.gray3};
      border: 1px solid ${colors.red3};
      color: ${colors.red6};
      svg > path {
        fill: ${colors.red6};
      }
      &:hover {
        background: ${colors.red1};
        border: 1px solid ${colors.red3};
        color: ${colors.red7};
        svg > path {
          fill: ${colors.red7};
        }
      }
      :active {
        border: 1px solid ${colors.red8};
        color: ${colors.red8};
        svg > path {
          fill: ${colors.red8};
        }
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: ${colors.gray4};
      border: 1px solid ${colors.gray4};
      color: ${colors.gray7};
      pointer-events: none;
      svg > path {
        fill: ${colors.gray7};
        stroke: ${colors.gray7};
      }
    `};
`

export { StyledButton, TextDiv, IconDiv, ButtonStyledLink }
