import Grid from '@mui/material/Grid'
import Layer from './Layer'
import Button from '../../../../components/Button/'
import { ReactComponent as Circles } from '../../../../icons/24px/Circles.svg'
import Accordion from '../../../../components/Accordion'
import UpdateWarningTooltip from '../../../../components/UpdateWarningTooltip'

export default function LayerManager({ updateFilter, chosen_audiences = [] }: any) {
  const needsSelection = chosen_audiences.reduce(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'pv' implicitly has an 'any' type.
    (pv, cv) => Math.max(pv, !!cv?.audience.id ? 0 : 1),
    0
  )
  function updateLayer(i: any, update: any) {
    const _chosen_audiences = [...chosen_audiences]
    _chosen_audiences[i] = {
      ..._chosen_audiences[i],
      ...update,
    }

    updateFilter({ chosen_audiences: _chosen_audiences })
  }

  function addLayer() {
    const _chosen_audiences = chosen_audiences.concat([
      {
        audience: {},
        stacked_type: 'And in',
      },
    ])
    updateFilter({ chosen_audiences: _chosen_audiences })
  }

  function removeLayer(idx: any) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
    const _chosen_audiences = chosen_audiences.filter((val, i) => i !== idx)
    updateFilter({ chosen_audiences: _chosen_audiences })
  }

  const layerCount = chosen_audiences?.length

  const Title = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Circles fill="black" style={{ marginRight: '1rem' }} />
      <span>{layerCount > 1 && layerCount} Layers Applied</span>
    </div>
  )

  return (
    (<Grid item xs={12} style={{ marginBottom: '1rem' }}>
      {layerCount ? (
        <Accordion
          title={Title}
          Widgets={
            !!needsSelection && (
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
              (<UpdateWarningTooltip text="Plase select an audience layer" />)
            )
          }
          summaryProps={{ style: { backgroundColor: 'white' } }}
          detailProps={{
            style: {
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'audience_layer' implicitly has an 'any'... Remove this comment to see the full error message */}
          {chosen_audiences.map((audience_layer, i) => {
            return (
              <Layer
                key={i}
                removeLayer={() => removeLayer(i)}
                audience_layer={audience_layer}
                updateLayer={(update: any) => updateLayer(i, update)}
              />
            )
          })}
          <Grid item xs={2}>
            <Button
              text="Add Layer"
              variant="secondary"
              onClick={addLayer}
              style={{
                padding: '0.5rem',
              }}
              testId="addLayer"
            />
          </Grid>
        </Accordion>
      ) : (
        <span style={{ display: 'flex', flex: 1 }}>
          <span id="audience_layers">
            <Button
              icon="circles"
              text="Add Audience Layer"
              variant="secondary"
              iconProps={{ style: { marginRight: '0.875rem' } }}
              onClick={addLayer}
              testId="addAudienceLayer"
            />
          </span>
        </span>
      )}
    </Grid>)
  );
}
