import React from 'react'
import { StyledButton, TextDiv, IconDiv } from './CSS'
import { ReactComponent as SaveIcon } from '../../icons/16px/Save.svg'
import { ReactComponent as CirclesIcon } from '../../icons/24px/Circles.svg'
import { ReactComponent as DownloadIcon } from '../../icons/16px/Download.svg'
import { ReactComponent as UploadIcon } from '../../icons/24px/Upload.svg'

interface Props {
  text?: any
  size?: string
  onClick: any
  icon?: any
  iconProps?: any
  textStyle?: any
  variant?: 'primary' | 'secondary' | 'warning' | 'default' | 'delete'
  style?: any
  disabled?: boolean
  id?: any
  testId?: string
}

const Button: React.FC<Props> = ({
  text = null,
  size = 'large',
  onClick,
  icon,
  iconProps,
  textStyle,
  disabled,
  testId = '',
  ...rest
}): JSX.Element => {
  const Content = (
    <>
      <TextDiv style={textStyle}>{text}</TextDiv>
    </>
  )

  let Icon = icon

  switch (icon) {
    case 'save':
      Icon = SaveIcon
      break
    case 'circles':
      Icon = CirclesIcon
      break
    case 'download':
      Icon = DownloadIcon
      break
    case 'upload':
      Icon = UploadIcon
      break
    default:
      break
  }

  const IconButton = (
    <>
      {icon && (
        <IconDiv {...iconProps}>
          <Icon />
        </IconDiv>
      )}
    </>
  )

  return (
    <span style={{ display: 'flex' }}>
      <StyledButton
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault()
          onClick(e)
        }}
        disabled={disabled}
        size={size}
        style={{ display: 'flex', flex: 1 }}
        data-cy={testId}
        {...rest}
      >
        {IconButton}
        {text && Content}
      </StyledButton>
    </span>
  )
}

export default Button
