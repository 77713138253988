import React from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import { colors } from '../context/theme-context/'

const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 100px;
  flex: 1;
`

const StyledCard = styled.div`
  display: flex;
  flex: 0.5;
  color: white;
  background-color: ${colors.blue5};
  padding: 10px 10px 100px;
  border-radius: 5px;
`

export default function LoremIpsumCard({
  greeting,
  bodyText,
  actionComponent,
}: any) {
  const cardText =
    bodyText ||
    `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco 
    laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit
  `

  const cardGreeting = greeting || 'Hello,'

  const cardActions = actionComponent || (
    <span>
      <Button variant="contained">Sì</Button>
      &nbsp;
      <Button variant="contained">Nœ</Button>
    </span>
  )

  return (
    <CenteredDiv>
      <StyledCard>
        <div>
          <Typography variant="h2" gutterBottom>
            {cardGreeting}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {cardText}
          </Typography>
          {cardActions}
        </div>
      </StyledCard>
    </CenteredDiv>
  )
}
