import Grid from '@mui/material/Grid'
import TagManager from './Dashboard/TagManager'
import LayerManager from './Dashboard/LayerManager'
import DashboardTabs from './Dashboard/DashboardTabs'
import { useMediaSize } from '../../../context/mediaQuery-context'
import React from 'react'
import { Props as AudienceDispatch } from '../Audiences/reducer'

interface Props {
  audienceInfo: any
  updateFilter: (payload: any) => void
  deleteFilter: (keys: Array<string>) => void
  appliedFilters: any
  selectedFilters: any
  filtersHaveUpdated: boolean
  audienceSize: any
  clearChanges: () => void
  resetCounter: () => void
  count: number
  sqlIsVerified: boolean
  sql_editor: string
  dispatch: React.Dispatch<AudienceDispatch>
  filters: any
  fields: any
  metricsDataReady: boolean
  metrics: any
  allDataMode: boolean
}

const Dashboard: React.FC<Props> = ({
  audienceInfo,
  updateFilter,
  deleteFilter,
  appliedFilters,
  selectedFilters,
  filtersHaveUpdated,
  audienceSize,
  clearChanges,
  resetCounter,
  count,
  sqlIsVerified,
  sql_editor,
  dispatch,
  filters,
  fields,
  metricsDataReady,
  metrics,
  allDataMode,
}) => {
  const { verySmallScreen } = useMediaSize()
  const allFilters = Object.entries({ ...selectedFilters })
    .filter(([key, value]: [string, any]) => {
      // These are filters we don't want counted toward the filter totals
      if (
        [
          'privacy',
          'good_catch_all',
          'good_only',
          'chosen_audiences',
          'allDataMode',
          'audience_type',
        ].includes(key)
      ) {
        return false
      } else if (value?.length === 0 || value === false) {
        return false
      } else {
        return true
      }
    })
    .map(([key, value]) => ({ [key]: value }))

  const { chosen_audiences } = selectedFilters

  return (
    <Grid
      item
      lg={9}
      sm={8}
      xs={12}
      style={{
        height: 'none',
        overflowY: verySmallScreen ? 'visible' : 'scroll',
      }}
    >
      <TagManager
        deleteFilter={deleteFilter}
        allFilters={allFilters}
        selectedFilters={selectedFilters}
        clearChanges={clearChanges}
        resetCounter={resetCounter}
        unappliedFilterCount={count}
        sqlIsVerified={sqlIsVerified}
        sql_editor={sql_editor}
        updateFilter={updateFilter}
        filters={filters}
        fields={fields}
        dispatch={dispatch}
      />
      <LayerManager
        updateFilter={updateFilter}
        chosen_audiences={chosen_audiences}
      />
      <DashboardTabs
        audienceSize={audienceSize}
        appliedFilters={appliedFilters}
        filtersHaveUpdated={filtersHaveUpdated}
        audienceInfo={audienceInfo}
        dispatch={dispatch}
        metricsDataReady={metricsDataReady}
        metrics={metrics}
        allDataMode={allDataMode}
      />
    </Grid>
  )
}

export default Dashboard
