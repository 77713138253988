import Typography from '@mui/material/Typography'

type Props = {
  version: string
}

export default function BuildVersion(props: Props) {
  return (
    <Typography
      data-cy="buildVersion"
      variant="body2"
      color="textSecondary"
      align="center"
    >
      Build version #{props.version}
    </Typography>
  )
}
