import Typography from '@mui/material/Typography'

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a style={{ color: 'inherit' }} href="https://netwisedata.com/">
        NetWise
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
