import styled from 'styled-components'
import { colors } from '../context/theme-context'
import { Popover } from '@mui/material'
import { useEffect } from 'react'
import PopupFilter from './PopupFilter'
import { FilterCategory } from '../types'
import { withStyles } from '@mui/styles';
interface Props {
  updateFilter: React.Dispatch<any>
  popupAnchor: HTMLElement
  setPopupState: React.Dispatch<
    React.SetStateAction<{
      popupAnchor: any
      isOpen: boolean
    }>
  >
  isOpen: boolean
  selectedFilters: any
  filters: any
  fields: Array<any>
  sqlIsVerified: boolean
  dispatch: React.Dispatch<any>
  filterType: FilterCategory
  resetCounter: any
  unappliedFilterCount: any
}

const PopupFilterBox: React.FC<Props> = ({
  popupAnchor,
  setPopupState,
  isOpen,
  updateFilter,
  selectedFilters,
  filters,
  fields,
  sqlIsVerified,
  dispatch,
  filterType,
  resetCounter,
  unappliedFilterCount,
}) => {
  useEffect(() => {
    if (popupAnchor) {
      setPopupState((state) => ({ ...state, isOpen: true }))
    }
  }, [popupAnchor, setPopupState])

  const FilterComponent = (
    <PopupFilter
      filterType={filterType}
      updateFilter={updateFilter}
      selectedFilters={selectedFilters}
      filters={filters}
      fields={fields}
      sqlIsVerified={sqlIsVerified}
      dispatch={dispatch}
      resetCounter={resetCounter}
    />
  )

  function handleClick() {
    resetCounter()
    setPopupState({
      popupAnchor: null,
      isOpen: false,
    })
  }

  if (!document.body.contains(popupAnchor)) {
    return null
  }

  return (
    <StyledPopover
      open={isOpen}
      onClose={() => {
        setPopupState({
          isOpen: false,
          popupAnchor: null,
        })
      }}
      anchorEl={popupAnchor}
    >
      <div style={{ display: 'flex' }} id="popup_filter">
        {FilterComponent}
        {unappliedFilterCount > 0 ? (
          <StyledSpan onClick={handleClick}>Apply</StyledSpan>
        ) : (
          <div />
        )}
      </div>
    </StyledPopover>
  )
}

export default PopupFilterBox

const StyledPopover = withStyles({
  root: {
    overflowY: 'scroll',
  },
  paper: {
    boxShadow: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    padding: '1rem',
    borderRadius: '0.5rem',
    border: `1px solid ${colors.gray5}`,
  },
})(Popover)

const StyledSpan = styled.span`
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: ${colors.blue6};
`
