import React from 'react'
import Grid from '@mui/material/Grid'
import LeftNav from 'App/SmartHub/Audiences/LeftNav'
import Dashboard from 'App/SmartHub/Audiences/Dashboard'
import NameAudience from 'App/SmartHub/Audiences/NameAudience'
import PrintableContent from 'App/SmartHub/Audiences/NameAudience/PrintableContent'
import NavigationPrompt from 'components/NavigationPrompt'
import reducer, { Props } from 'App/SmartHub/Audiences/reducer'
import { useAuth } from 'context/auth-context'
import { useParams, useHistory } from 'react-router-dom'
import { printPdf, filterDiff, defaults } from 'App/SmartHub/Audiences/utils'

export default function Audiences() {
  const { client, allDataMode, userData, buildVersion } = useAuth()
  const { audiencesIdAPI, filtersAPI, audienceFieldsAPI } = client.endpoints
  const [state, dispatch] = React.useReducer(reducer, defaults.initialState)
  const { hash: audienceHash } = useParams<{ hash: string | undefined }>()
  const history = useHistory<{ action: string }>()

  /**************/
  /* UseEffects */
  /**************/

  React.useEffect(() => {
    // Handles reseting of audience page state, sometimes necessary depending on
    // where in the app the user is navigating from or if a new audience is being created.
    if (history.location.state?.action === 'reset') {
      history.location.state.action = 'non-reset'
      const {
        audienceInfo,
        appliedFilters,
        selectedFilters,
        savedFilters,
      } = defaults.initialState
      dispatch({
        payload: {
          audienceInfo,
          appliedFilters,
          selectedFilters,
          savedFilters,
        },
      })
    }
  }, [client, audienceFieldsAPI, filtersAPI, history.location.state])

  React.useEffect(() => {
    // Load audience filters. Additionally, If there's an audience hash in the URL,
    // use it to request the saved audience values from the API.
    async function fetchFilters() {
      try {
        const [
          { responseData: filterResponse },
          { responseData: fieldsResponse },
        ] = await Promise.all([
          client.get({ endpoint: filtersAPI, data: { include_negatives: true } }),
          client.get({
            endpoint: audienceFieldsAPI,
            data: {
              name_only: 1,
              include_negatives: true,
            },
          }),
        ])

        const { results: filters } = filterResponse
        const { results: fields } = fieldsResponse

        dispatch({
          payload: {
            filters: filters,
            fields: fields,
          },
        })
      } catch (e) {
        console.log('Could not retrieve data at this time: ', e)
      }
    }

    async function fetchAudience() {
      // If loading an audience (i.e. audience hash exists in URL), then fetch all filters
      // associated with this audience.
      try {
        const { responseData } = await client.get({
          endpoint: audiencesIdAPI(audienceHash),
        })

        const { results: audienceData } = responseData
        const { data, created } = audienceData
        data.privacy = audienceData.privacy

        dispatch({
          payload: {
            selectedFilters: { ...data },
            appliedFilters: { ...data },
            savedFilters: { ...data },
            initialLocation: {
              company: data.company_location_country,
              contact: data.contact_location_country,
            },
            saveIsCurrent: true,
            audienceInfo: {
              audience_description: audienceData.description,
              audience_id: audienceData.id,
              audience_id_hashed: audienceData.hashed,
              audience_name: audienceData.name,
              created_by: created.user.first_name + ' ' + created.user.last_name,
              editable: audienceData.editable,
            },
          },
        })
      } catch (e) {
        console.log('Could not retrieve data at this time.')
      }
    }

    if (audienceHash) {
      fetchAudience()
      fetchFilters()
    } else {
      fetchFilters()
    }

    return () => {
      client.abortFetch()
    }
  }, [
    audienceHash,
    audiencesIdAPI,
    client,
    audienceFieldsAPI,
    filtersAPI,
    allDataMode,
  ])

  React.useEffect(() => {
    // Monitoring the dependency array below, use filterDiff() to
    // determine whether the save is current and update the state to reflect this
    // in the UI.
    const diff = filterDiff(
      state.selectedFilters,
      state.appliedFilters,
      state.sqlIsVerified
    )
    if (diff > 0) {
      dispatch({
        payload: {
          filtersHaveUpdated: false,
          newFilterCount: diff,
          saveIsCurrent: false,
        },
      })
    } else {
      dispatch({
        payload: {
          filtersHaveUpdated: true,
          newFilterCount: diff,
          saveIsCurrent:
            filterDiff(
              state.appliedFilters,
              state.savedFilters || {},
              state.sqlIsVerified
            ) === 0,
        },
      })
    }
  }, [
    state.selectedFilters,
    state.appliedFilters,
    state.savedFilters,
    state.allDataMode,
    state.sqlIsVerified,
  ])

  /************************************/
  /* Functions tied to filter updates */
  /************************************/
  function updateFilter(payload: Props['payload']): void {
    dispatch({ type: 'updateFilter', payload })
  }

  function deleteFilter(keys: Array<string>): void {
    dispatch({ type: 'deleteFilter', payload: { keys } })
  }

  function clearChanges(): void {
    // Clear all pending changes by setting the currently selected filters to the
    // filters which have already been applied.
    dispatch({ payload: { selectedFilters: state.appliedFilters } })
  }

  function resetCounter(): void {
    // Controls the count displayed on the <Apply Changes> button.
    dispatch({
      payload: {
        newFilterCount: 0,
        appliedFilters: { ...state.selectedFilters },
        filtersHaveUpdated: true,
      },
    })
  }

  /***********************/
  /* Component Rendering */
  /***********************/
  return (
    <Grid item container justifyContent="center">
      <NameAudience
        filtersHaveUpdated={state.filtersHaveUpdated}
        audienceInfo={state.audienceInfo}
        selectedFilters={state.selectedFilters}
        appliedFilters={state.appliedFilters}
        updateFilter={updateFilter}
        audienceSize={state.audienceSize}
        printPdf={printPdf}
        dispatch={dispatch}
        metricsDataReady={state.metricsDataReady}
        countsDataReady={state.countsDataReady}
        allDataMode={allDataMode}
      />
      <Grid container item xs={12} spacing={2}>
        <LeftNav
          filters={state.filters}
          fields={state.fields}
          updateFilter={updateFilter}
          resetCounter={resetCounter}
          count={state.newFilterCount}
          sqlIsVerified={state.sqlIsVerified}
          clearChanges={clearChanges}
          selectedFilters={state.selectedFilters}
          dispatch={dispatch}
        />
        <Dashboard
          audienceInfo={state.audienceInfo}
          selectedFilters={state.selectedFilters}
          appliedFilters={state.appliedFilters}
          filtersHaveUpdated={state.filtersHaveUpdated}
          filters={state.filters}
          fields={state.fields}
          audienceSize={state.audienceSize}
          clearChanges={clearChanges}
          resetCounter={resetCounter}
          count={state.newFilterCount}
          sqlIsVerified={state.sqlIsVerified}
          sql_editor={state.selectedFilters.sql_editor}
          updateFilter={updateFilter}
          deleteFilter={deleteFilter}
          dispatch={dispatch}
          metricsDataReady={state.metricsDataReady}
          metrics={state.metrics}
          allDataMode={allDataMode}
        />
        <PrintableContent
          dataReady={state.metricsDataReady && state.countsDataReady}
          buildVersion={buildVersion}
          printingUser={userData.first_name + ' ' + userData.last_name}
          audienceInfo={state.audienceInfo}
          appliedFilters={state.appliedFilters}
          audienceSize={state.audienceSize}
          printableMetrics={state.printableMetrics}
        />
      </Grid>
      <NavigationPrompt
        message="You have unsaved changes that will be lost, proceed anyway?"
        blockCondition={!state.saveIsCurrent}
      />
    </Grid>
  )
}
