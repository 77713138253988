import React from 'react'
import { useAuth } from '../../../context/auth-context'
import BasicInfo from './AccountDetail/BasicInfo'
import CustomInfo from './AccountDetail/CustomInfo'
import AdvancedInfo from './AccountDetail/AdvancedInfo'
import AccountsCreditsSchedule from './AccountDetail/AccountsCreditsSchedule'
import { Redirect, useHistory } from 'react-router-dom'
import { AccountInfo } from './adminTypes'
import ContentArea from 'components/ContentArea'
import Button from 'components/Button'
import Grid from '@mui/material/Grid'
import Alert from 'components/Alert'
import makeInitialAdminState from './adminInitialState'
import { useAlerts } from 'context/alert-context'
import { toTitleCase } from 'utils/caseHelpers'

export default function AccountDetail() {
  const { client, userData } = useAuth()
  const { sendAlert } = useAlerts()
  const history = useHistory()
  const canCreateAccounts = userData.admin || userData.account.admin

  const [hasCreditSchedule, setHasCreditSchedule] = React.useState(false)
  const [state, setState] = React.useState<AccountInfo>(
    makeInitialAdminState({ valuesUndefined: false })
  )
  const [alertState, setAlertState] = React.useState({
    isOpen: false,
    message: 'Account Created',
  })

  const { basicInformation, advancedInformation } = state
  const { customFields, referenceInformation, creditSchedule } = state

  const hideAlert = () => {
    setAlertState((state) => ({ ...state, isOpen: false }))
  }

  const save = async () => {
    if (customFields.accountTypeRefId === undefined) {
      setAlertState({message: "Account Type is required", isOpen: true})
      return
    }

    const account = {
      name: basicInformation.name,
      domain: basicInformation.domain,
      account_stage: basicInformation.stage,
      account_signup_source: basicInformation.signupSource,
      is_active: basicInformation.isActive,
      expires: basicInformation.expires,
      is_admin_account: false,

      grant_access_to_pii_fields: customFields.piiAccess,
      restrict_access_to_analysis_only_fields: customFields.analysisOnly,
      restrict_access_to_anonymized_fields: customFields.anonymizedOnly,
      restricted_fields: customFields.restrictedFields,
      additional_fields: customFields.additionalFields,
      account_type_ref_id: customFields.accountTypeRefId,
      layout_filters: customFields?.layoutFilters,

      credits_unlimited: advancedInformation.creditsUnlimited,
      credits_unlimited_anonymized: advancedInformation.creditsUnlimitedAnonymized,
      s3_delivery_folder: advancedInformation.s3DeliveryFolder,
      record_ownership_retention_days: advancedInformation.ownershipRetentionDays,
      disable_uploads: advancedInformation.disableUploads,
      suppress_from_crm: advancedInformation.suppressFromCRM,

      test_account: referenceInformation.testAccount,
      monthly_invoice_amount: referenceInformation.monthlyInvoiceAmount,
      currently_paying: referenceInformation.currentlyPaying,
      salesforce_url: basicInformation.salesforceUrl,
      total_contract_value: basicInformation.totalContractValue,
      csm_owner: basicInformation.csmOwner,
      order_number: basicInformation.orderNumber,
      contract_start_date: basicInformation.contractStartDate,
      contract_end_date: basicInformation.contractEndDate,
      enable_appends: false,
      enable_upload_duns: false,
      enable_export_duns: false,
    }

    const schedule = {
      active: creditSchedule.active,
      credit_schedule_start_year: creditSchedule.scheduleStartYear,
      credit_schedule_start_month: creditSchedule.scheduleStartMonth,
      credits: creditSchedule.credits || 0,
      credit_schedule: creditSchedule.creditSchedule || 'monthly',
      credit_limit: creditSchedule.creditLimit || 0,
    }
    try {
      const url = client.endpoints.accountsAPI
      const { responseData } = await client.post({ endpoint: url, data: account })
      const { results, message: errorMessage } = responseData

      if (errorMessage) {
        const [key, value] = Object.entries(errorMessage)[0]
        setAlertState({message: toTitleCase(key) + ': ' + value, isOpen: true})
      }

      if (results?.id) {
        const newUrl = `/admin/accounts/detail/${results.id}`
        if (hasCreditSchedule) {
          setTimeout(async () => {
            await client.put({
              endpoint: url + '/' + results.id + '/credits_schedule',
              data: schedule,
            })
            history.push(newUrl)
          }, 1000)
        } else {
          history.push(newUrl)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateInfo = (update: Partial<AccountInfo>) => {
    const fn = (state: any) => ({ ...state, ...update })
    setState(fn)
  }

  return canCreateAccounts && userData ? (
    <ContentArea style={{ padding: '0.5rem 2rem 1rem' }}>
      <BasicInfo basicInfo={basicInformation} updateInfo={updateInfo} />
      <CustomInfo customFields={customFields} updateInfo={updateInfo} />
      <AdvancedInfo advancedInfo={advancedInformation} updateInfo={updateInfo} />
      <AccountsCreditsSchedule
        creditSchedule={creditSchedule}
        updateInfo={updateInfo}
        hasCreditSchedule={hasCreditSchedule}
        removeCreditSchedule={() => setHasCreditSchedule(false)}
        addCreditSchedule={() => setHasCreditSchedule(true)}
      />
      <Grid container justifyContent="center" style={{ columnGap: '1rem' }}>
        <Grid item>
          <Button
            text={'Create Account'}
            onClick={save}
            variant="primary"
            data-cy="createAccount"
          />
        </Grid>
      </Grid>
      <Alert
        alertIsOpen={alertState.isOpen}
        message={alertState.message}
        handleOk={hideAlert}
        handleClose={hideAlert}
      />
    </ContentArea>
  ) : (
    <Redirect to="audiences" />
  )
}
