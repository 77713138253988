import { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button/'
import { colors, typography } from '../../../context/theme-context/'
import Slide from '@mui/material/Slide'
import { Grid } from '@mui/material'
import { useAlerts } from '../../../context/alert-context'
import tm from '../../../analytics/TagManager'

interface FooterPropsI {
  width?: number
  resetCounter: any
  count: number
  sqlIsVerified: boolean
  sql_editor: string
  clearChanges: any
}

const ApplyFooter: React.FunctionComponent<FooterPropsI> = ({
  width,
  resetCounter,
  count,
  sqlIsVerified,
  sql_editor,
  clearChanges,
}) => {
  const { sendAlert } = useAlerts()
  const handleClick = useCallback(() => {
    if (sqlIsVerified || sql_editor === '') {
      setTimeout(resetCounter, 100)
      tm.captureCustomEvent('audiences_apply_changes_button')
    } else {
      sendAlert({ message: 'Please correct SQL before submitting' })
    }
  }, [resetCounter, sqlIsVerified, sql_editor, sendAlert])

  useEffect(() => {
    function handleKeyDown(e: any) {
      const tagName = document?.activeElement?.tagName?.toLowerCase() || ''
      const isFocused =
        ['input', 'textarea'].includes(tagName) && tagName !== ':checkbox'
      if (!isFocused && e.key === 'u') {
        handleClick()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [resetCounter, handleClick])

  return (
    <Slide direction="right" in={count > 0}>
      <Footer width={width || 0}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Button
              onClick={clearChanges}
              variant="secondary"
              text="Clear"
              style={{ padding: '0.75rem', flex: 1 }}
              testId="clearChangesBottom"
            />
          </Grid>
          <Grid item xs={9}>
            <Button
              onClick={handleClick}
              variant="primary"
              id="apply_changes_left_nav"
              text={`Apply Changes (${count})`}
              style={{ padding: '0.75rem', flex: 1 }}
              testId="applyChangesBottom"
            />
          </Grid>
        </Grid>
      </Footer>
    </Slide>
  )
}

const { big } = typography.bodyBold
const Footer = styled.div<{ width: number }>`
  display: flex;
  font-size: ${big.fontSize};
  font-weight: ${big.fontWeight};
  line-height: ${big.lineHeight};
  background-color: ${colors.gray1};
  box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.15);
  border: 1px solid ${colors.gray5};
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 1rem;
  position: absolute;
  bottom: 0px;
  width: ${(props) => `calc(${props.width}px)`};
  transition: bottom 2s;
  justify-content: center;
`

export default ApplyFooter
