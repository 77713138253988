import React from 'react'
import styled from 'styled-components'
import getDefaultDataTableProps from 'components/RemoteDataTable/getDefaultDataTableProps'
import MUIDataTable from 'mui-datatables'
import Skeleton from 'react-loading-skeleton'
import ExpandButton from './DataTable/ExpandButton'
import TableDialog from './DataTable/TableDialog'
import Controls from './DataTable/Controls'
import RemoteDataTableProps from './RemoteDataTable/remoteDataTableProps'
import reducer from './RemoteDataTable/remoteDataTableReducer'
import getDefaultTableState from './RemoteDataTable/getDefaultTableState'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { SimpleCheckbox } from './Checkbox'
import { colors } from 'context/theme-context'
import { log } from 'utils/debug'


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export function RemoteDataTable(defaultProps: RemoteDataTableProps) {
  const props = getDefaultDataTableProps(defaultProps)
  const defaultState = getDefaultTableState(props.rowsOnPage, props.initialSortOrder)
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  React.useEffect(() => {
    // Occasionally there will be the need to pass updated text through
    // to the table component. This allows the table to react to those changes
    dispatch({ payload: { searchText: props.externalSearchText } })
  }, [props.externalSearchText])

  const getCurrentParams = React.useCallback(() => {
    // Occassionally the filter text will need to be passed to a special
    // url parameter which corresponds to an ad-hoc filter on the backend.
    // One such example of this is found in LoadAudience.tsx / audience.py (name)
    const adhocParam: Record<string, string> = {}
    if (props.adhocParam) {
      if (typeof props.adhocParam === 'string') {
        adhocParam[props.adhocParam] = state.searchText
      } else if (Array.isArray(props.adhocParam)) {
        props.adhocParam.forEach((param: string) => {
          adhocParam[param] = state.searchText
        })
      }
    }
    let currentParams = {
      include_autosave: null,
      ...props.urlParams,
      ...adhocParam,
      filter: state.searchText,
    }

    if (props.autosaveControl) {
      currentParams.include_autosave = state.autosaveDisplay
    }

    return currentParams
  }, [
    props.urlParams,
    state.searchText,
    props.autosaveControl,
    state.autosaveDisplay,
    props.adhocParam,
  ])

  const getData = React.useCallback(
    async (getURL, params = {}) => {
      try {
        const currentParams = getCurrentParams()
        const data = { ...currentParams, ...params }
        dispatch({ type: 'toggleInflight' })
        const { responseData } = await props.client.get({
          endpoint: getURL,
          data: data,
          useCache: false,
        })
        dispatch({ type: 'toggleInflight' })
        let filteredData = responseData

        return filteredData
      } catch (e) {
        console.log(e)
      }
    },
    [props.client, getCurrentParams, props.adminMode] // eslint-disable-line
  )

  React.useEffect(() => {
    const params = {
      page: state.page + 1,
      size: state.rowsPerPage,
      sort: state.sortOrder,
    }

    const callbackFn = (payload: any) => {
      try {
        const { results, total_size } = payload

        let filteredResults = results
        if (props.filterPredicates) {
          filteredResults = props.filterPredicates.reduce(
            (data: any, predicate: any) => {
              return data.filter(predicate)
            },
            results
          )
        }

        dispatch({ payload: { data: filteredResults, count: total_size } })
      } catch (e: any) {
        log(e)
      }
    }

    async function initialDataPull() {
      const payload = await getData(props.url, params)
      callbackFn(payload)
    }

    initialDataPull()
  }, [
    state.autosaveDisplay,
    props.refreshState,
    state.searchText,
    props.url,
    state.sortOrder,
    state.rowsPerPage,
    state.page,
    state.refreshCount,
    getData,
    props.filterPredicates,
    getCurrentParams,
  ])

  /*
   * This code WAITS for a service call to return a response before firing the next call.
   * Previously service calls would be fired every 5 seconds indiscriminately,
   * This would cause requests on the backend to stackup. Now every time a service call
   * completes it will refresh 5 seconds later.
   */
  React.useEffect(() => {
    if (props.refreshAtInterval && !props.useWebSocket && !state.isCallInflight) {
      const interval = setTimeout(() => {
        !state.isCallInflight && dispatch({ type: 'refresh' })
      }, 5000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [props.refreshAtInterval, props.useWebSocket, state.isCallInflight])

  function translateSortOrder(order: any) {
    let sort = state.sortOrder
    if (Object.entries(order).length > 0) {
      const prefix = order.direction === 'desc' ? '-' : ''
      let name = props.columnOrderMap[order.name] || order.name
      if (!Array.isArray(name)) {
        name = [name]
      }
      sort = name.map((n: any) => prefix + n)
    }
    return sort
  }

  function updateRowSelections(selections: any) {
    let newSelectedRows = [...state.selectedRows]
    selections.forEach((selection: any) => {
      const idx = selection.dataIndex
      if (state.selectedRows.includes(idx)) {
        newSelectedRows = state.selectedRows.filter((index: any) => index !== idx)
      } else {
        newSelectedRows.push(idx)
      }
    })
    dispatch({ payload: { selectedRows: newSelectedRows } })
  }

  async function deleteObj({ hashed, undo = false }: any) {
    try {
      if (undo) {
        const { status } = await props.client.put({
          endpoint: props.deleteAPI(hashed) + '/undelete',
        })
        return status
      } else {
        const { status } = await props.client.delete({
          endpoint: props.deleteAPI(hashed),
        })
        return status
      }
    } catch (e) {
      console.log('Action failed: ', e)
    }
  }

  async function deleteAction(data: any) {
    if (state.selectedRows) {
      const hashes = state.selectedRows?.map((idx: any) => data[idx].hashed)

      try {
        const statuses = await Promise.all(
          hashes.map((hash: any) => deleteObj({ hashed: hash }))
        )

        const rowIdentifier =
          state.selectedRows.length === 1
            ? props.getHumanRowIdentifier?.(data[0])
            : 'Rows'

        if (statuses.every((item) => item === 200)) {
          props.setSnackbarMessage({
            text: `${rowIdentifier} Deleted`,
            undoAction: async () => {
              await await Promise.all(
                hashes.map((hash: any) => deleteObj({ hashed: hash, undo: true }))
              )
            },
          })
        } else {
          await await Promise.all(
            hashes.map((hash: any) => deleteObj({ hashed: hash, undo: true }))
          )
          props.sendAlert({
            message:
              'We were unable to delete one or more records. ' +
              'All deletions have been undone. ' +
              'Please check to see if you have the necessary authorization, and try again.',
          })
        }
        dispatch({ payload: { selectedRows: [], refresh: true } })
      } catch (e) {
        console.log(e)
        props.setSnackbarMessage({
          text: 'Sorry, could not delete. Please try again later.',
        })
      }
      dispatch({ type: 'refresh' })
    }
  }

  function onTableChange(action: any, state: any) {
    switch (action) {
      case 'changePage':
        dispatch({ payload: { page: state.page } })
        break
      case 'sort':
        let sort = state.sortOrder
        if (Object.entries(state.sortOrder).length > 0) {
          sort = translateSortOrder(state.sortOrder)
        }
        dispatch({ payload: { sortOrder: sort } })
        break
      case 'changeRowsPerPage':
        dispatch({
          payload: {
            rowsPerPage: state.rowsPerPage,
            page: state.rowsPerPage >= state.count ? 0 : state.page,
          },
        })
        break
      case 'propsUpdate':
        break
      default:
        console.log(`Missing case in switch() to handle ${action}`)
    }
  }

  function showDialog(index: any) {
    dispatch({
      payload: {
        isOpen: true,
        data: state.data[index],
      },
    })
  }

  // Additional components that will be rendered in <MUIDataTable />
  const components = {
    ExpandButton: (props: any) => (
      <ExpandButton showDialog={showDialog} {...props} />
    ),
    TableToolbarSelect: () => null,
    Checkbox: ({ checked, onChange }: any) => (
      <div style={{ padding: '1rem' }}>
        <div />
      </div>
    ),
  }

  // Options to pass to the <MUIDataTable/> we're wrapping
  const tableOptions = {
    fixedSelectColumn: false,
    elevation: 0,
    expandableRows: props.expandableRows,
    fixedHeader: true,
    selectableRows: props.selectableRows,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    resizableColumns: false,
    print: false,
    filter: false,
    search: false,
    customSearchRender: () => <div />,
    customToolbar: () => null,
    customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
      const selected = state.selectedRows.includes(dataIndex)
      return (
        <StyledRow key={'l+' + dataIndex}>
          {props.selectableRows !== 'none' ? (
            <StyledCell>
              <div
                style={{ padding: '1rem' }}
                id={`${props.title}_checkbox_${rowIndex}`}
                data-cy={`${props.title}_checkbox`}
              >
                <SimpleCheckbox
                  selected={selected}
                  onClick={() =>
                    updateRowSelections([{ index: rowIndex, dataIndex }])
                  }
                />
              </div>
            </StyledCell>
          ) : null}
          {data.map((item: any, i: any) => (
            <StyledCell
              id={`${props.title}_${rowIndex}_${i}`}
              data-cy={`${props.title}_${rowIndex}_${i}`}
              key={i}
            >
              {item}
            </StyledCell>
          ))}
        </StyledRow>
      )
    },
    download: false,
    searchText: state.searchText,
    viewColumns: false,
    rowsSelected: state.selectedRows,
    serverSide: true,
    count: state.count,
    rowsPerPage: state.rowsPerPage,
    sortOrder: {},
    page: state.page,
    onTableChange,
    rowsPerPageOptions: [5, 10, 15, 50, 100],
    responsive: props.responsive,
  }

  const setSearchText = (text: string) => {
    dispatch({ payload: { searchText: text, page: 0 } })
  }

  return (
    (<div id={props.title}>
      {props.controls && (
        <div style={{ marginBottom: '1rem' }}>
          <Controls
            setSearchText={setSearchText}
            setColumns={props.setColumns}
            setAutosaveDisplay={(value: boolean) =>
              dispatch({ payload: { autosaveDisplay: value } })
            }
            autosaveControl={props.autosaveControl}
            debounceDelay={props.debounceDelay}
            deleteControl={props.deleteControl}
            deleteAction={() => deleteAction(state.data)}
            searchControl={props.searchControl}
            excelControl={props.excelControl}
            csvControl={props.csvControl}
            copyControl={props.copyControl}
            selectLayout={props.selectLayout}
            warningControl={props.outOfDate}
            customControls={props.customControls}
            TitleComponent={props.TitleComponent}
            rowsAreSelected={state.selectedRows.length > 0}
            data-cy={props.testId}
          />
        </div>
      )}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={props.outOfDate ? props.disabledTheme : props.theme}>
          {state.data ? (
            <div data-cy={props.testId} style={{ overflowY: 'hidden' }}>
              <MUIDataTable
                title={undefined}
                options={tableOptions as any}
                columns={props.columns}
                data={props.customRowRender(state.data)}
                components={components}
              />
            </div>
          ) : (
            <div data-cy={props.testId + '-skeleton'}>
              <Skeleton count={10} height={37} />
            </div>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
      <TableDialog
        isOpen={state.dialogState.isOpen}
        handleClose={() => dispatch({ payload: { isOpen: false } })}
        data={state.dialogState.data}
      />
    </div>)
  );
}

export default RemoteDataTable

const StyledRow = styled.tr`
  white-space: nowrap;
  background-color: none;
  &:nth-of-type(even) {
    background-color: ${colors.gray2};
  }
  &:hover {
    background-color: ${colors.gray3};
  }
`

const StyledCell = styled.td`
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  border: 1px solid ${colors.gray4};
  padding: 0.5rem;
  max-width: 50ch;
  .Mui-checked {
    color: ${colors.blue6};
  }
`
