import { ReactComponent as NetWiseLogo } from '../Logo.svg'
import LinearProgress from '@mui/material/LinearProgress'
import styled from 'styled-components'
import { colors } from '../context/theme-context/'

export default function LoadingScreen() {
  return (
    <LoadingContainer>
      <LogoContainer>
        <NetWiseLogo height={64} width={64} />
        <span>NetWise</span>
      </LogoContainer>
      <StyledProgress />
    </LoadingContainer>
  )
}

const LoadingContainer = styled.div`
  display: flex;
  row-gap: 1rem;
  flex: 1;
  width: 100%;
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LogoContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 45px;
  color: ${colors.blue8};
  text-rendering: optimizeSpeed;
`

const StyledProgress = styled(LinearProgress)`
  width: 400px;
`
