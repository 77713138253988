import { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as Expand } from '../../icons/24px/Expand.svg'
import { colors } from '../../context/theme-context/'

const Container = styled.div`
  display: flex;
  height: 3rem;
  width: 3rem;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  color: ${colors.gray7};
  cursor: pointer;
  &:hover {
    background-color: ${colors.blue1};
    border: 1px solid ${colors.blue2};
    svg > path {
      stroke: ${colors.blue8};
    }
  }
`

export default function ExpandButton({ dataIndex, showDialog, isHeaderCell }: any) {
  const [mouseIsHover, setMouseIsHover] = useState(false)
  const options = {
    onMouseEnter: () => {
      setMouseIsHover(true)
    },
    onMouseLeave: () => setMouseIsHover(false),
  }

  const ExpandButton = mouseIsHover ? (
    <Expand height={'12px'} width={'12px'} />
  ) : (
    <span>{dataIndex + 1}</span>
  )

  const Element = !isHeaderCell ? (
    <Container onClick={() => showDialog(dataIndex)} {...options}>
      {ExpandButton}
    </Container>
  ) : (
    <div />
  )
  return Element
}
