import Grid from '@mui/material/Grid'
import TableBarChart from '../../../../../components/TableBarChart'
import {
  BiBuildings,
  BiRuler,
  BiIdCard,
  BiGroup,
  BiChart,
  BiMailSend,
  BiBriefcase,
  BiUserCircle,
} from 'react-icons/bi'
import { formatMetricsForDisplay } from '../DashboardTabs/utils'

export default function MetricsTab({
  metrics,
  filtersHaveUpdated,
  audienceSize,
}: any) {
  const fmtData = formatMetricsForDisplay(metrics)
  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <TableBarChart
          title="Contacts"
          rows={fmtData.contactData?.rows}
          Icon={BiMailSend}
          filtersHaveUpdated={filtersHaveUpdated}
          denominator={audienceSize?.contacts}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TableBarChart
          title="Companies"
          rows={fmtData.companyData?.rows}
          Icon={BiBuildings}
          filtersHaveUpdated={filtersHaveUpdated}
          denominator={audienceSize?.companies}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TableBarChart
          title="Size"
          rows={fmtData.employeeData?.rows}
          Icon={BiRuler}
          filtersHaveUpdated={filtersHaveUpdated}
          denominator={audienceSize?.contacts}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TableBarChart
          title="Revenue"
          rows={fmtData.revenueData?.rows}
          Icon={BiChart}
          filtersHaveUpdated={filtersHaveUpdated}
          denominator={audienceSize?.contacts}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TableBarChart
          title="Contact Titles"
          rows={fmtData.titleData?.rows}
          Icon={BiIdCard}
          filtersHaveUpdated={filtersHaveUpdated}
          denominator={audienceSize?.contacts}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TableBarChart
          title="Contact Job Functions"
          rows={fmtData.functionalData?.rows}
          Icon={BiGroup}
          filtersHaveUpdated={filtersHaveUpdated}
          denominator={audienceSize?.contacts}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TableBarChart
          title="Seniority"
          rows={fmtData.seniorityData?.rows}
          Icon={BiUserCircle}
          filtersHaveUpdated={filtersHaveUpdated}
          denominator={audienceSize?.contacts}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TableBarChart
          title="Industry"
          rows={fmtData.industryData?.rows}
          Icon={BiBriefcase}
          filtersHaveUpdated={filtersHaveUpdated}
          denominator={audienceSize?.contacts}
        />
      </Grid>
    </Grid>
  )
}
