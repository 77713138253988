import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import colors from '../../context/theme-context/colors'
import withStyles from '@mui/styles/withStyles'
import styled from 'styled-components'

const StyledAccordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 500,
    margin: '0rem',
    padding: '0rem',
    '&$expanded': {
      margin: '0.5rem 0rem',
    },
  },
  expanded: {},
})(Accordion)

const StyledAccordionSummary = withStyles({
  root: {
    padding: '0rem',
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  expandIconWrapper: {
    padding: '12px',
  },
  content: {
    margin: '0rem 0rem',
    '&$expanded': {
      margin: '0rem 0rem',
    },
  },
  expanded: {},
})(AccordionSummary)

const _AccordionDetails = withStyles({
  root: {
    padding: '0.5rem 0.5rem 1rem',
  },
})(AccordionDetails)

const StyledAccordionDetails = styled(_AccordionDetails)`
  transition: all 1s;
`

const Title = styled.div<{ expanded?: boolean }>`
  color: ${(props) => (props.expanded ? colors.gray11 : colors.blue8)};
`

const Count = styled.div`
  display: inline;
  margin-left: 0.25rem;
  color: ${colors.gray7};
`

const BottomBorder = styled.div`
  border-bottom: 1px solid ${colors.gray5};
  width: 100%;
  margin-left: 0.5rem;
`

export {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  Title,
  Count,
  BottomBorder,
}
