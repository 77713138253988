//@ts-nocheck
import { useState, useEffect } from 'react'
import { SimpleTreeView } from '@mui/x-tree-view'
import CheckboxTreeItem from './Tree/CheckboxTreeItem'
import { ReactComponent as Plus } from '../icons/16px/Plus.svg'
import { ReactComponent as Minus } from '../icons/16px/Minus.svg'
import TreeSearch from './Tree/TreeSearch'
import TreeButtons from './Tree/TreeButtons'

function initializeState({
  options,
  initialState,
  treeType = 'audience',
  isSelected = false,
  existingState = [],
}) {
  let allApplied = []
  if (treeType === 'audience') {
    allApplied = initialState?.map((item) => [item[0], item[1]]).flat() || []
  } else if (treeType === 'layout') {
    allApplied = initialState?.map((item) => item[0]) || []
  }

  function findAllHidden() {
    let names = []
    function isNodeHidden(node) {
      if (node.isHidden) {
        names.push(node.functionName)
      }
      if (node?.subFunctions) {
        node.subFunctions.map((subNode) => isNodeHidden(subNode))
      }
    }
    existingState.map((node) => isNodeHidden(node))
    return names
  }

  const allHidden = findAllHidden()

  function isValueSelected(key, values) {
    if (allApplied.includes(key)) {
      return true
    }
    if (values) {
      if (Array.isArray(values)) {
        return values
          .map((val) => isValueSelected(val, null))
          .reduce((pv, cv) => Math.max(pv, cv), 0)
      }
      return Object.entries(values)
        .map(([k, v]) => isValueSelected(k, v))
        .reduce((pv, cv) => Math.max(pv, cv), 0)
    }
    return false
  }

  if (!options) {
    return []
  }

  const makeTreeItem = (idx, name, isRoot = false, isSelected = false) => {
    return {
      id: String(idx),
      functionName: name,
      isSelected: isSelected,
      isExpanded: false,
      isHidden: allHidden.includes(name),
      isRoot: isRoot,
    }
  }

  let idx = 0
  function generateGrouping(key, values) {
    idx += 1
    const isFunctionSelected = isValueSelected(key, values) || isSelected
    const isRoot = !!values

    if (isRoot) {
      let rootProperties = makeTreeItem(idx, key, isRoot, isFunctionSelected)

      let subFunctions
      if (Array.isArray(values)) {
        subFunctions = values.map((item) => {
          if (Array.isArray(item)) {
            return item.map((value) => generateGrouping(value, null))
          }
          if (typeof item === 'string') {
            return generateGrouping(item, null)
          }

          const key = Object.keys(item)[0]
          const values = Object.values(item)
          return generateGrouping(key, values)
        })
      } else {
        const keys = Object.keys(values)
        subFunctions = keys.map((key) => generateGrouping(key, values[key]))
      }

      return {
        ...rootProperties,
        subFunctions: subFunctions,
      }
    }

    return makeTreeItem(idx, key, isRoot, isFunctionSelected)
  }

  const standardTreeItems = []
  Object.entries(options).forEach((item) => {
    const [key, values] = item
    const grouping = generateGrouping(key, values)
    standardTreeItems.push(grouping)
  })

  return standardTreeItems
}

export default function Tree({
  updateFilter,
  options,
  searchLabel = null,
  initialState,
  isPopup = false,
}) {
  const defaults = initializeState({ options, initialState })
  const [treeData, setData] = useState(defaults)
  const [expandedNodes, setExpandedNodes] = useState([])
  const [selectedOnly, setSelectedOnly] = useState(isPopup)

  function updateExpandedNodes(id, bool) {
    let _expandedNodes
    if (bool) {
      _expandedNodes = [...expandedNodes, id]
    } else {
      _expandedNodes = expandedNodes.filter((item) => item !== id)
    }
    setExpandedNodes(_expandedNodes)
  }

  function setTreeData(data, propagate = true) {
    if (propagate) {
      updateFilter(data)
    }
    setData(data)
  }

  useEffect(() => {
    setData((state) => {
      const newState = initializeState({
        options,
        initialState,
        existingState: state,
      })
      return newState
    })
  }, [options, initialState])

  const filterNodes = (e) => {
    const filterValue = e.target.value.trim()

    function search(item) {
      const regexp = new RegExp(filterValue, 'i')
      const subFunctions = item?.subFunctions?.map((subFn) => search(subFn))

      if (filterValue === '') {
        return { ...item, subFunctions, isExpanded: false, isHidden: false }
      }

      const match = item.functionName.match(regexp)
      const subFnMatch = subFunctions?.reduce(
        (pv, cv) => Math.max(pv, cv.isExpanded),
        0
      )

      if (!!match || !!subFnMatch) {
        return { ...item, subFunctions, isExpanded: true, isHidden: false }
      } else {
        return { ...item, subFunctions, isExpanded: false, isHidden: true }
      }
    }

    function getExpandedItems(item) {
      const expandedItems = item?.subFunctions?.map((subFn) =>
        getExpandedItems(subFn)
      )
      if (item.isExpanded) {
        return [item.id, expandedItems]
      }
    }

    const _treeData = treeData.map((item) => search(item))
    setTreeData(_treeData, false)

    const _expandedNodes = _treeData.map((item) => getExpandedItems(item))

    // Below taken from:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#reduce_concat_isarray_recursivity
    function flatDeep(arr, d = 1) {
      return d > 0
        ? arr.reduce(
            (acc, val) =>
              acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
            []
          )
        : arr.slice()
    }

    const flattenedNodes = flatDeep(_expandedNodes, Infinity).filter(
      (item) => typeof item !== 'undefined'
    )

    setExpandedNodes(flattenedNodes)
  }

  const toggleExpansion = (id) => {
    function searchNode(node) {
      if (node.id === id) {
        const newState = !node.isExpanded
        updateExpandedNodes(id, newState)
        return { ...node, isExpanded: newState }
      } else if (node.subFunctions) {
        const searchedSubfunctions = node.subFunctions.map((subFn) =>
          searchNode(subFn)
        )
        return { ...node, subFunctions: searchedSubfunctions }
      }
      return node
    }

    const _treeData = treeData.map((node) => searchNode(node))
    setTreeData(_treeData, false)
  }

  const toggleSelection = (id) => {
    const _treeData = treeData.map((_node) => {
      function updateNode(root, bool) {
        if (root.subFunctions) {
          return {
            ...root,
            isSelected: bool,
            subFunctions: root.subFunctions.map((x) => updateNode(x, bool)),
          }
        } else {
          return { ...root, isSelected: bool }
        }
      }

      function findSelectedNode(node) {
        if (node.id === id) {
          return updateNode(node, !node.isSelected)
        } else if (node.subFunctions) {
          const subFunctions = node.subFunctions.map((subNode) => {
            return findSelectedNode(subNode)
          })
          return { ...node, subFunctions }
        } else {
          return node
        }
      }

      function updateRoot(node) {
        if (node.subFunctions) {
          const { subFunctions } = node
          const isSelected = subFunctions.map((x) => x.isSelected).includes(true)

          const _subFunctions = subFunctions.map((subFn) => updateRoot(subFn))
          return { ...node, isSelected, subFunctions: _subFunctions }
        } else {
          return node
        }
      }

      const selectedNode = findSelectedNode(_node)
      // TODO: This is stupid and needs to be updated to account for # of levels
      const updatedRoot1 = updateRoot(selectedNode)
      const updatedRoot2 = updateRoot(updatedRoot1)
      const updatedRoot3 = updateRoot(updatedRoot2)
      const updatedRoot4 = updateRoot(updatedRoot3)
      return { ...updatedRoot4 }
    })
    setTreeData(_treeData)
  }

  function selectAll() {
    const _data = initializeState({ options, isSelected: true })
    setTreeData(_data)
  }

  function clearAll() {
    const _data = initializeState({ options, isSelected: false })
    setTreeData(_data)
  }

  function renderNode(node) {
    return (
      <CheckboxTreeItem
        key={node.id}
        itemId={node.id}
        node={node}
        toggleExpansion={toggleExpansion}
        toggleSelection={toggleSelection}
      >
        {Array.isArray(node.subFunctions)
          ? node.subFunctions.map((subNode) => renderNode(subNode))
          : null}
      </CheckboxTreeItem>
    )
  }

  let _treeData = treeData

  if (selectedOnly) {
    _treeData = _treeData.filter((item) => item.isSelected)
  }

  const Nodes = _treeData.map((node) => renderNode(node))
  const allSelected = !treeData.filter((item) => !item.isSelected).length

  return (
    <>
      <TreeSearch filterNodes={filterNodes} label={searchLabel} />
      <TreeButtons
        isPopup={isPopup}
        selectAll={selectAll}
        toggleSelectedOnly={() => setSelectedOnly((state) => !state)}
        selectedOnly={selectedOnly}
        clearAll={clearAll}
        allSelected={allSelected}
      />
      <SimpleTreeView
        expandedItems={expandedNodes} // changed from expanded to expandedItems
        // The below properties were moved into the 'slots' attribute
        slots={{
          collapseIcon: Minus,
          expandIcon: Plus
        }}
      >
        {Nodes}
      </SimpleTreeView>
    </>
  )
}
