import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../icons/24px/Close.svg'
import { colors } from '../context/theme-context/'

const StyledDialogTitle = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.gray2};
`

const ModalTitle = styled.div`
  flex: 1;
  margin: 1rem;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
`

const CloseButton = styled(IconButton)`
  margin: 0rem 1rem;
  padding: 0rem;
`

const paperProps = {
  style: {
    borderRadius: '0.5rem',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    margin: '1rem',
    border: `1px solid ${colors.gray5}`,
  },
}

interface StandardDialogI {
  title: any
  isOpen: boolean
  handleClose: any
  Content: any
  ActionButtons?: any
  dialogStyle?: any
  titleStyle?: any
  fullWidth?: boolean
  maxWidth?: any
}

const StandardDialog: React.FC<StandardDialogI> = ({
  isOpen,
  title,
  Content,
  handleClose,
  ActionButtons,
  dialogStyle = {},
  titleStyle = {},
  ...rest
}) => {
  return (
    <Dialog
      PaperProps={paperProps}
      open={isOpen}
      onClose={(event, reason) => {
        // The prop `disableBackdropClick` of `ForwardRef(Modal)` is deprecated
        // 'reason' can be used instead to identify backdropClick's
        if(reason !== 'backdropClick') {
            handleClose(event);
        }
      }}
      maxWidth="md"
      disableEnforceFocus={true} //!NOTE: see accessbility warning here: https://material-ui.com/api/modal/
      {...rest}
    >
      <StyledDialogTitle>
        <ModalTitle style={titleStyle}>{title}</ModalTitle>
        <CloseButton onClick={handleClose} data-cy="dialogCloseButton">
          <CloseIcon />
        </CloseButton>
      </StyledDialogTitle>
      <DialogContent style={{ padding: '1rem', ...dialogStyle }} dividers>
        {Content}
      </DialogContent>
      {ActionButtons && <DialogActions>{ActionButtons}</DialogActions>}
    </Dialog>
  )
}

export default StandardDialog
