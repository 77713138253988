import styled from 'styled-components'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import ChipBasket from './ChipFilter/ChipBasket'
import {
  StyledAutocomplete,
  AutocompleteLabel,
  StyledListBox,
} from './StandardAutocomplete/Css'
import { colors } from '../context/theme-context/'
import { ReactComponent as ChevronDown } from '../icons/16px/Chevron/Down.svg'
import { ReactComponent as Close } from '../icons/16px/Close.svg'
import { InputAdornment } from '@mui/material'
import { useState, useEffect } from 'react'
import Tooltip from './Tooltip/Tooltip'
import Button from './Button'

interface Props {
  label?: string
  testId?: string
  highlightColor?: string
  value?: any
  onChange?: (_: any, [str]: any) => void
  tagSuggestions?: any
  disabled?: boolean
  multiple?: boolean
  variant?: string
  freeSolo?: boolean
  placeholder?: string
  LeftAdornment?: any
  RightAdornment?: any
  labelProps?: any
  autocompleteProps?: any
  renderOption?: any
  disableClearable?: boolean
  size?: string
  style?: any
  id?: string
  rest?: any
  tooltip?: boolean
  'data-cy'?: string
  EndComponent?: React.ReactNode
}

const StandardAutocomplete: React.FC<Props> = (props) => {
  const { multiple = true, disableClearable = true } = props
  const [basketValues, setBasketValues] = useState(props.value || [])
  const testId = props['data-cy'] ?? ''

  useEffect(() => {
    setBasketValues(props.value || [])
  }, [props.value])

  function renderTags(values: any, getTagProps: any) {
    if (!multiple) {
      const chips = values?.map((value: any, i: any) => (
        <StyledChip
          deleteIcon={<StyledClose />}
          key={i}
          disabled={true}
          value={value}
          label={value}
          {...getTagProps({ index: i })}
        />
      ))
      return chips
    }
  }

  // renderOption now takes a 'props: object' property which needs to be included 
  // in the function definition, props then needs to be spread as and attribute 
  // on the main element of the returned component 
  function renderTooltip(props: object, option: any, state: object) {
    return (
      <Tooltip
        data-cy={`standardAutocomplete-tooltip-${testId}`}
        title={option}
        key={option}
        {...props}
      >
        <div>{option}</div>
      </Tooltip>
    )
  }

  // Only render the tooltip if requested by the 'tooltip' prop. The default is no tooltip rendering.
  const renderOption = props.tooltip ? renderTooltip : props.renderOption

  return (
    <div style={{ flex: 1 }} data-cy={testId} id={props?.id} {...props.rest}>
      {props.label && (
        <AutocompleteLabel {...props.labelProps}>{props.label}</AutocompleteLabel>
      )}
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, paddingRight: '0.25rem' }}>
            <StyledAutocomplete
              value={multiple ? props.value || [] : props.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>, values: any) => {
                if (props.onChange) {
                  setBasketValues(values)
                  props.onChange(e, values)
                }
              }}
              options={props.tagSuggestions ?? []}
              renderInput={(params: any) => {
                return (
                  <TextField
                    {...params}
                    autoComplete="off"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment
                            position="start"
                            style={{ marginRight: 0 }}
                          >
                            {props.LeftAdornment ? (
                              <props.LeftAdornment
                                style={{ marginLeft: '0.5rem' }}
                              />
                            ) : (
                              <div />
                            )}
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <div style={{ marginLeft: '0.5rem' }}>
                              {props.RightAdornment ? <props.RightAdornment /> : ''}
                            </div>
                          </InputAdornment>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    inputProps={{
                      value: !multiple ? props.value : null,
                      ...params.inputProps,
                      'data-cy': props.testId,
                      style: {
                        padding: '0.15rem',
                      },
                    }}
                    variant="outlined"
                    placeholder={
                      params.InputProps.startAdornment ? '' : props.placeholder
                    }
                  />
                )
              }}
              freeSolo={props.freeSolo}
              multiple={multiple}
              size="small"
              disabled={props.disabled}
              disableCloseOnSelect={false}
              disableClearable={disableClearable}
              autoHighlight={true}
              popupIcon={<ChevronDown />}
              noOptionsText={
                <span style={{ ...listStyle, fontSize: '16px', padding: '1rem' }}>
                  No Options
                </span>
              }
              renderTags={renderTags}
              renderOption={renderOption}
              PaperComponent={(params: any) => (
                <StyledListBox highlightColor={props.highlightColor} {...params} />
              )}
              ListboxProps={{ style: listStyle }}
              {...props.autocompleteProps}
            />
          </div>
          {props?.EndComponent && props.EndComponent}
        </div>
        {basketValues.length > 0 && multiple && (
          <div style={{ paddingTop: '0.5rem' }}>
            <ChipBasket
              data-cy={testId}
              selectedOptions={basketValues}
              handleChipDelete={(option: any) => {
                if (!props.disabled) {
                  const filteredValues = basketValues.filter(
                    (item: any) => item !== option
                  )
                  setBasketValues(filteredValues)
                  if (props.onChange) props.onChange('_', filteredValues)
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

// Additional Styles
const StyledChip = styled(Chip)`
  border-radius: 0.25rem;
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
`
const StyledClose = styled(Close)`
  rect:nth-child(1) {
    stroke: ${colors.gray5};
  }
  rect:nth-child(n + 2) {
    fill: ${colors.gray7};
  }
`
const listStyle = {
  borderRadius: '0.25rem',
  backgroundColor: colors.gray1,
  border: `1px solid ${colors.gray6}`,
  fontSize: '0.875rem',
  color: colors.gray8,
}

export default StandardAutocomplete
export { listStyle }
