import styled from 'styled-components'
import StandardDialog from '../StandardDialog'
import Button from '../Button/'
import { toTitleCase } from '../../utils/caseHelpers'
import { colors } from '../../context/theme-context/'

const Table = styled.table`
  border-spacing: 0px;
`

// const Header = styled.th`
//   color: ${colors.gray8};
//   text-align: left;
//   padding: 0.5rem;
//   border-left: 1px solid ${colors.gray4};
// `

const Body = styled.tbody``
const Row = styled.tr`
  &:nth-of-type(even) {
    background-color: ${colors.gray3};
  }
`
const Cell = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid ${colors.gray4};
  margin: 0;
`

export default function TableDialog({ isOpen, handleClose, data }: any) {
  return (
    <StandardDialog
      title={'Row Detail'}
      isOpen={isOpen}
      handleClose={handleClose}
      Content={generateContent(data)}
      ActionButtons={
        <div style={{ padding: '0.25rem' }}>
          <Button
            text={'OK'}
            variant="primary"
            style={{ padding: '0.5rem 1rem' }}
            onClick={handleClose}
            testId="rowDetailOK"
          />
        </div>
      }
      dialogStyle={{ padding: '1rem' }}
    />
  )
}

const generateContent = (data: any) => {
  if (!data) {
    return <div />
  }
  return (
    <Table>
      {/* <Row>
        <Header>Column</Header>
        <Header>Value</Header>
      </Row> */}
      <Body>
        {Object.entries(data).map(([key, value], index) => (
          <Row data-cy={`rowDetail_${index}`}>
            <Cell style={{ fontWeight: 500 }} data-cy="cell1">
              {toTitleCase(key)}
            </Cell>
            <Cell data-cy="cell2">{value}</Cell>
          </Row>
        ))}
      </Body>
    </Table>
  )
}
