import AnimatedRefresh from 'components/AnimatedRefresh'
import Button from 'components/Button'
import ClientDataTable from 'components/ClientDataTable'
import Checkbox from 'components/Checkbox'
import UserDialog from './UserDialog'
import tm from 'analytics/TagManager'
import ItemWithFadeButton from 'components/ItemWithFadeButton'
import { User } from 'types'
import { numericHumanReadable } from 'utils/numericHelpers'
import { toTitleCase } from 'utils/caseHelpers'
import { useState } from 'react'
import { useAuth } from 'context/auth-context'
import { useSnackbar } from 'context/snackbar-context'
import { PasswordResetDialog } from './PasswordResetDialog'
import { IoMdLock as Lock } from 'react-icons/io'
import { colors } from 'context/theme-context'
import Tooltip from 'components/Tooltip'
import { useAlerts } from 'context/alert-context'

type Props = {
  triggerUpdate: () => void
  account_id: number
  title: string
  users: Array<User>
  changeStatus: (user: User, payload?: any) => Promise<void>
  Expiration: JSX.Element
}

type EditableUser = {
  id?: number
  first_name: string
  last_name: string
  email: string
  account_admin: boolean
  account: number
}

function makeDefaultUserState(id: number): EditableUser {
  return {
    id: undefined,
    first_name: '',
    last_name: '',
    email: '',
    account_admin: false,
    account: id,
  }
}

export default function UserTable(props: Props) {
  // Custom Hooks
  const { setSnackbarMessage } = useSnackbar()
  const { userData, requestReset, client } = useAuth()
  const { sendAlert } = useAlerts()

  // State
  const [markedIndexes, setMarkedIndexes] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [resetTarget, setResetTarget] = useState('')
  const [user, setUser] = useState(makeDefaultUserState(props.account_id))

  // Derived Variables
  const userExists = user?.id
  const canCreateUsers = userData.admin || userData.account.admin

  // Utility function for updating state
  function updateUserState(payload: any) {
    setUser((state) => ({ ...state, ...payload }))
  }

  // Mark an index as being selected by the user
  function markIndex(indexes: any) {
    for (const item of indexes) {
      if (
        markedIndexes.map((idx) => (idx as any)?.dataIndex).includes(item.dataIndex)
      ) {
        const _markedIndexes = markedIndexes.filter(
          (idx) => item.dataIndex !== (idx as any)?.dataIndex
        )
        setMarkedIndexes(_markedIndexes)
      } else {
        const _markedIndexes = [...markedIndexes, item]
        setMarkedIndexes(_markedIndexes as never[])
      }
    }
  }

  // Special function that will be used to extract the text from some
  // elements which contain text as the first child
  function valueToText(value: any) {
    if (typeof value === 'string') return value
    if (typeof value === 'object') {
      const text = value?.props?.children?.[0]
      return text
    }
    return undefined
  }

  async function unlockUser(user: User) {
    try {
      const res = await client.put({
        endpoint: client.endpoints.usersIdAPI(user.id),
        data: { ...user, locked: false },
      })

      if (res.ok) {
        // This alert will trigger a component refresh, since a message is being passed to somewhere higher in the tree.
        // If this were not the case, we would need to use props.triggerUpdate() to refresh the table and display
        // the latest information.
        sendAlert({ message: `You have successfully unlocked ${user.email}` })
      }
    } catch {
      console.log('Could not unlock user at this time')
    }
  }

  // Either create or update the user, based on whether or not userExists
  async function createOrUpdateUser() {
    try {
      const url = userExists
        ? client.endpoints.usersIdAPI(user.id)
        : client.endpoints.usersAPI

      const res = await client.makeFetch({
        method: userExists ? 'PUT' : 'POST',
        endpoint: url,
        data: user,
        useCache: false,
      })

      if (res.ok) {
        setIsOpen(false)
        if (!userExists) await requestReset(user.email)

        props.triggerUpdate()
        setSnackbarMessage({
          text: `User ${user.first_name} ${user.last_name} ${
            userExists ? 'updated' : 'created'
          }`,
        })

        tm.captureCustomEvent('admin_create_new_user')

        setErrorMessage('')
        setUser({
          id: undefined,
          first_name: '',
          last_name: '',
          email: '',
          account_admin: false,
          account: props.account_id,
        })
      } else {
        setErrorMessage(res?.errorMessage)
      }
    } catch (e) {
      console.log('Could not create user. Please try again later: ' + e)
    }
  }

  // Formatting the data before passing it to the <ClientDataTable /> component, where it will be displayed.
  // Some columns need special styling, or contain other nested elements aside from text.
  const fmtUsers = props.users?.map((user: User) => {
    const { admin, active, id } = user
    return {
      email: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.25rem',
          }}
        >
          {user.email}
          <div onClick={() => setResetTarget(user.email)}>
            <AnimatedRefresh />
          </div>
          {user.locked && (
            <Tooltip title="Account locked">
              <div
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => unlockUser(user)}
              >
                <Lock color={colors.red6} data-cy="accountLocked" />
              </div>
            </Tooltip>
          )}
        </div>
      ),
      name: (
        <div>
          <ItemWithFadeButton
            buttonText="Edit"
            data-cy="editUser"
            item={toTitleCase(`${user.last_name}, ${user.first_name}`)}
            onClick={() => {
              setIsOpen(true)
              setUser({
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                account_admin: user.admin,
                account: props.account_id,
              })
            }}
          />
        </div>
      ),
      credits_used: user.credits?.used,
      user_credit_limit: user.credits?.total,
      created: user.created.timestamp?.local,
      last_login: user.last_login?.local,
      credits_used_trailing_year: numericHumanReadable(
        user.usage?.credits?.trailing_year,
        0
      ),
      records_used_trailing_year: numericHumanReadable(
        user.usage?.records?.trailing_year,
        0
      ),
      admin: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            status={admin}
            disabled={userData.id === id}
            onClick={() => props.changeStatus(user, { account_admin: !admin })}
          />
        </div>
      ),
      active: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            status={active}
            disabled={userData.id === id}
            onClick={() => props.changeStatus(user, { is_active: !active })}
          />
        </div>
      ),
    }
  })

  // Define the columns to be used in the table.
  // Some columns need specialized sorting logic, which is defined here as well.
  const columns = [
    { name: 'email', label: 'Email' },
    {
      name: 'name',
      label: 'Name',
      options: {
        sortCompare: (order: any) => {
          return (obj1: any, obj2: any) => {
            const val1 = obj1?.data?.props?.children?.props?.item
            const val2 = obj2?.data?.props?.children?.props?.item

            if (val1 < val2) {
              return order === 'asc' ? -1 : 1
            }
            if (val1 > val2) {
              return order === 'asc' ? 1 : -1
            }
            return 0
          }
        },
      },
    },
    {
      name: 'created',
      label: 'Created',
      options: {
        sortCompare: (order: any) => {
          return (obj1: any, obj2: any) => {
            let val1 = new Date(obj1.data || 0)
            let val2 = new Date(obj2.data || 0)
            let result = ((val1 as any) - (val2 as any)) * (order === 'asc' ? 1 : -1)
            return result
          }
        },
      },
    },
    {
      name: 'last_login',
      label: 'Last Login',
      options: {
        sortCompare: (order: any) => {
          return (obj1: any, obj2: any) => {
            let val1 = new Date(obj1.data || 0)
            let val2 = new Date(obj2.data || 0)
            let result = ((val1 as any) - (val2 as any)) * (order === 'asc' ? 1 : -1)
            return result
          }
        },
      },
    },
    {
      name: 'credits_used_trailing_year',
      label: 'Credits Used (TTM)',
      options: {
        sortCompare: (order: any) => {
          return (obj1: any, obj2: any) => {
            let val1 = parseInt(obj1.data.replace(/,/g, ''), 10)
            let val2 = parseInt(obj2.data.replace(/,/g, ''), 10)
            return (val1 - val2) * (order === 'asc' ? 1 : -1)
          };
        },
      },
    },
    {
      name: 'records_used_trailing_year',
      label: 'Records Used (TTM)',
      options: {
        sortCompare: (order: any) => {
          return (obj1: any, obj2: any) => {
            let val1 = parseInt(obj1.data.replace(/,/g, ''), 10)
            let val2 = parseInt(obj2.data.replace(/,/g, ''), 10)
            return (val1 - val2) * (order === 'asc' ? 1 : -1)
          };
        },
      },
    },
    {
      name: 'admin',
      label: 'Admin',
      options: {
        sortCompare: (order: any) => {
          return (obj1: any, obj2: any) => {
            const val1 = obj1?.data?.props?.children?.props?.status ? 1 : 0
            const val2 = obj2?.data?.props?.children?.props?.status ? 1 : 0
            return (val1 - val2) * (order === 'asc' ? 1 : -1)
          }
        },
      },
    },
    {
      name: 'active',
      label: 'Active',
      options: {
        sortCompare: (order: any) => {
          return (obj1: any, obj2: any) => {
            const val1 = obj1?.data?.props?.children?.props?.status ? 1 : 0
            const val2 = obj2?.data?.props?.children?.props?.status ? 1 : 0
            return (val1 - val2) * (order === 'asc' ? 1 : -1)
          }
        },
      },
    },
  ]

  return (
    // | TITLE                                EXPIRATION |
    // |                   [Create New User]    [Search] |
    // | ----------------------------------------------- |
    // | ----------------------------------------------- |
    (<>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
        <div style={{ flex: 1 }}>
          <h3>{props.title}</h3>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <h4>{props.Expiration}</h4>
        </div>
      </div>
      <div style={{ paddingTop: '1rem' }}>
        <ClientDataTable
          title="accountTable"
          markSelected={(e: any) => markIndex(e)}
          rowsSelected={markedIndexes.map((idx) => (idx as any).index) as never[]}
          columns={columns}
          data={fmtUsers}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          customControls={[
            () =>
              canCreateUsers &&
              ((
                <div>
                  <Button
                    size="medium"
                    data-cy="adminCreateUserButton"
                    text={'Create New User'}
                    onClick={() => {
                      setIsOpen(true)
                      setUser(makeDefaultUserState(props.account_id))
                    }}
                    variant="secondary"
                  />
                </div>
              ) as never),
          ]}
          valueToText={valueToText}
          {...{ 'data-cy': 'adminUserTable' }}
        />
        <PasswordResetDialog
          requestReset={async (email) => {
            const res = await requestReset(email)
            if (res.ok) {
              props.triggerUpdate()
            }
          }}
          resetTarget={resetTarget}
          setResetTarget={setResetTarget}
        />
        <UserDialog
          isOpen={isOpen}
          title={userExists ? 'Update User' : 'Create New User'}
          type={userExists ? 'Update' : 'Create'}
          action={createOrUpdateUser}
          user={user}
          closeDialog={() => {
            setIsOpen(false)
            setErrorMessage('')
          }}
          onChange={updateUserState}
          errorMessage={errorMessage}
          client={client}
        />
      </div>
    </>)
  );
}
