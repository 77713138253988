import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as ChevronDown } from '../../icons/24px/Chevron/Down.svg'
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './Css'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '../../components/Tooltip'

interface Props {
  title?: any
  children?: any
  summaryProps?: any
  detailProps?: any
  iconProps?: any
  expanded?: boolean
  Widgets?: any
  id?: string
  expandDisabled?: boolean
  infoText?: string
}

const Accordion: React.FC<Props> = (props) => {
  const {
    title,
    children,
    Widgets,
    id,
    iconProps = {},
    detailProps = {},
    summaryProps = null,
    expanded = true,
    expandDisabled = false,
    infoText
  } = props

  const [isExpanded, setIsExpanded] = useState<boolean>(expanded || true)

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  function handleIconClick(e: React.ChangeEvent) {
    e.stopPropagation()
    if (!expandDisabled) {
      setIsExpanded((state) => !state)
    }
  }

  function handleSummaryClick() {
    if (!expandDisabled && !Widgets) {
      setIsExpanded((state) => !state)
    }
  }

  function handleTagClick() {
    setIsExpanded(true)
  }

  // This replaces the 'IconButtonProps' attribute on <StyledAccordionSummary> which was deprecated.
  // The style that was previously in 'IconButtonProps' (style: { padding: '6px' }) was moved to 
  // 'theme-content.tsx'
  iconProps['onClick'] = handleIconClick

  const summaryRef: React.Ref<any> = useRef()
  summaryRef.current?.addEventListener('tagClick', handleTagClick)

  return (
    <StyledAccordion expanded={isExpanded} id={id}>
      <StyledAccordionSummary
        expandIcon={<ChevronDown {...iconProps} />}
        ref={summaryRef}
        id={title}
        onClick={handleSummaryClick}
        style={{ cursor: !!Widgets ? 'default' : 'pointer' }}
        {...summaryProps}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex' }}>{title}
            {
              infoText && <Tooltip
                title={infoText}
              >
                <InfoOutlinedIcon
                  style={{ marginLeft: '0.25rem', height: '1rem', width: '1rem' }}
                  data-cy="accordianInfoIcon"
                />
              </Tooltip>
            }
          </div>
          <div style={{ display: 'flex' }}>{Widgets}</div>
        </div>
      </StyledAccordionSummary>
      <StyledAccordionDetails
        style={{ display: 'flex', flexDirection: 'column' }}
        {...detailProps}
      >
        {children}
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

export default Accordion
