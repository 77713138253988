import { useEffect, useState } from 'react'
import { Drawer, Toolbar } from '@mui/material'
import { colors } from '../../../context/theme-context'
import { IconButton } from '@mui/material'
import { Menu } from '@mui/icons-material'
import AppBar from '@mui/material/AppBar'
import AppLinks from './AppLinks'
import styled from 'styled-components'
import LogoNav from './TopNav/LogoNav'
import ProfileNav from './TopNav/ProfileNavigation'
import Modes from './TopNav/Modes'
import { useAuth } from '../../../context/auth-context'
import { useLocation } from 'react-router-dom'
import { useMediaSize } from '../../../context/mediaQuery-context'

export default function TopNav({ saveIsCurrent, setSaveIsCurrent }: any) {
  const location = useLocation()
  const { mediumScreen: smallScreen } = useMediaSize()
  const [isOpen, setIsOpen] = useState(false)

  const [selected, setSelected] = useState({
    top: location.pathname.split('/')[1],
    sub: 'New Audience',
  })

  const { userData } = useAuth()
  const canViewModes = userData.account.admin
  const canViewAdmin = userData.admin || userData.account.admin

  useEffect(() => {
    setSelected((state) => ({
      ...state,
      top: location.pathname.split('/')[1],
    }))
  }, [location.pathname])

  function toggleDrawer() {
    setIsOpen((state) => !state)
  }

  return (<>
    <AppBar
      position="static"
      id="AppBar"
      style={{
        background: colors.gray1,
        boxShadow: 'none',
        borderBottom: `1px solid ${colors.gray5}`,
      }}
    >
      <Toolbar variant="dense" style={{ padding: 0 }}>
        <LinkBar>
          {smallScreen && (
            <div>
              <IconButton
                onClick={toggleDrawer}
                style={{ margin: '0rem 0rem 0rem 0rem' }}
                disableFocusRipple
                size="large">
                <Menu />
              </IconButton>
            </div>
          )}
          <LogoNav />
          <VerticalDivider />
          {!smallScreen && (
            <AppLinks
              selected={selected}
              canViewAdmin={canViewAdmin}
              canViewModes={canViewModes}
              saveIsCurrent={saveIsCurrent}
              setSaveIsCurrent={setSaveIsCurrent}
            />
          )}
        </LinkBar>
        <LogoutButtonContainer>
          {canViewModes && !smallScreen && (
            <ModesContainer>
              <Modes />
            </ModesContainer>
          )}
          <ProfileNav selected={selected} />
        </LogoutButtonContainer>
      </Toolbar>
    </AppBar>
    <Drawer open={isOpen}>
      <AppLinks
        selected={selected}
        smallScreen={smallScreen}
        toggleDrawer={toggleDrawer}
        canViewAdmin={canViewAdmin}
        canViewModes={canViewModes}
      />
    </Drawer>
  </>);
}

const VerticalDivider = styled.div`
  margin: 1rem 0rem;
  border-right: 1px solid ${colors.gray4};
`
const LogoutButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
const LinkBar = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`
const ModesContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`
