import React from 'react'
import styled from 'styled-components'
import BaseGeneralSection from './BaseGeneralSection'
import tm from 'analytics/TagManager'
import Skeleton from 'react-loading-skeleton'
import Button from './Button'
import LimitsAndOrderingSection from './ExportDialog/LimitsAndOrderingSection'
import FileConfigurationSection from './ExportDialog/FileConfigurationSection'
import FavoriteButton from 'App/SmartHub/Audiences/Dashboard/DashboardTabs/ExportsTab/FavoriteButton'
import StandardDialog from './StandardDialog'
import { makeInitialExportState, createPayload } from './Exports'
import { useAlerts } from 'context/alert-context'
import { colors } from 'context/theme-context'
import { useAuth } from 'context/auth-context'
import RequestUserConfirmation from './RequestUserConfirmation'
import { LinearProgress } from '@mui/material'
import { getDateTime } from 'utils/dateTimeFormatter'

export type Props = {
  isOpen: boolean
  handleExit: () => any
  setRenderExportAudience?: any
  chosenExport: any
  disabled?: boolean
  audienceInfo?: any
  appliedFilters?: Required<{
    chosen_files: Array<any>
    chosen_audiences: Array<any>
  }>
  submissionCallback?: any
  dialogType: 'create' | 'edit' | 'quick' | 'run' | 'view'
  credits?: number | null
  calculateCreditUsage?: (hashed: string) => any
  creditsToBeUsed?: number
}

export default function BaseExportDialog(props: Props) {
  const { client, addNotifierTask, userData } = useAuth()
  const initialState = makeInitialExportState({
    seedExport: props.chosenExport,
    type: props.dialogType,
    currentUserEmail: userData.email,
  })
  const [layoutList, setLayoutList] = React.useState([])
  const [prioritizeFields, setPrioritizeFields] = React.useState([])
  const [partitionFields, setPartitionFields] = React.useState([])
  const [showAppendsCheckbox, setShowAppendsCheckbox] = React.useState(false)
  const { sendAlert } = useAlerts()
  const [exportState, setExportState] = React.useState(initialState)
  const [shouldUpdate, setShouldUpdate] = React.useState(false)
  const ep = client.endpoints
  const { chosenExport, calculateCreditUsage } = props

  function exit() {
    // Some state should be reset to its initial state when closing the dialog.
    // Do this here:
    setShowAppendsCheckbox(false)
    setShouldUpdate(true)
    props.handleExit()
  }

  React.useEffect(() => {
    if (Object.keys(props.chosenExport).length > 0 || shouldUpdate) {
      const seedState = makeInitialExportState({
        seedExport: props.chosenExport,
        type: props.dialogType,
        currentUserEmail: userData.email,
      })
      setShouldUpdate(false)
      setExportState(seedState)
    }
  }, [props.chosenExport, props.dialogType, userData.email])

  async function submitExport() {
    try {
      if (!exportState.name) {
        sendAlert({ message: 'Please add an export name' })
        return
      }
      if (exportState.file_name.includes('/')) {
        sendAlert({ message: 'File name cannot include a slash character'})
        return
      }

      let exportStateWithName = { ...exportState }
      // If a user has not chosen a file name, then assign a file name
      // based on the audience name and the current date and time.
      if (exportState.file_name === null || exportState.file_name.trim() === '') {
        const timestamp = getDateTime()
        const audienceName = props.audienceInfo?.audience_name || 'audience'
        const newFileName = `${audienceName}_${timestamp}`
        exportStateWithName = { ...exportState, file_name: newFileName }
      }

      const payload = createPayload({
        hash: props.chosenExport.hashed,
        exportState: exportStateWithName,
        dialogType: props.dialogType,
        audienceInfo: props.audienceInfo,
        appliedFilters: props.appliedFilters,
      })

      if (['edit', 'create'].includes(props.dialogType) && !payload['layout']) {
        sendAlert({ message: 'Layout required' })
        return
      }

      let res: any
      switch (props.dialogType) {
        case 'create':
          res = await client.post({
            endpoint: client.endpoints.exportsAPI,
            data: payload,
          })
          break
        case 'edit':
          res = await client.put({
            endpoint: ep.exportsIdAPI(props.chosenExport.hashed),
            data: payload,
          })
          break
        case 'run':
          res = await client.post({
            endpoint: ep.executionsAPI,
            data: payload,
          })
          const { responseData: postResponse } = res
          const responseWithMessage = postResponse.tasks.map((obj: any) => ({
            ...obj,
            message: 'Export finished. Click here to download.',
            clickAction: async () => {
              const { responseData } = await client.get({
                endpoint: ep.executionAPI(postResponse.results.hashed),
                data: {
                  download_urls: true,
                },
              })
              const { download_url } = responseData.results.results
              window.open(download_url)
            },
          }))
          addNotifierTask(responseWithMessage)
          break
        default:
          break
      }
      if (res.ok) {
        props.setRenderExportAudience?.()
        props.handleExit()
        props.submissionCallback?.()
        tm.captureCustomEvent('saved_exports_run')
      } else {
        const message = res.errorMessage || 'Could not complete action'
        sendAlert({ message })
      }
    } catch (e) {
      console.log('Could not create export', e)
    }
  }
  React.useEffect(() => {
    async function getEnableAppends() {
      try {
        const { responseData } = await client.post({
          endpoint: ep.enableAppendsAPI,
          data: {
            additional_audience: {
              chosen_files: props.appliedFilters?.chosen_files,
              chosen_audiences: props.appliedFilters?.chosen_audiences,
            },
            settings: {
              layout: exportState.layout,
            },
          },
        })
        const { should_enable_appends } = responseData
        setShowAppendsCheckbox(should_enable_appends)
      } catch (e) {
        console.log('Could not get list of enable appends')
      }
    }
    if (props.isOpen && exportState.layout) {
      getEnableAppends()
    }
  }, [
    client,
    ep.enableAppendsAPI,
    props.appliedFilters?.chosen_files,
    props.appliedFilters?.chosen_audiences,
    exportState.layout,
    props.isOpen,
  ])

  React.useEffect(() => {
    async function getLayoutList() {
      try {
        const {
          responseData: { results: _layoutList },
        } = await client.get({
          endpoint: ep.layoutsAPI,
          data: {
            size: 100,
            sort: ['-last_used', '-modified'],
            dropdown_view: true,
          },
        })
        setLayoutList(_layoutList)
      } catch (e) {
        console.log('Could not get list of layouts')
      }
    }
    getLayoutList()
  }, [client, ep.layoutsAPI])

  React.useEffect(() => {
    async function getPrioritizeFields() {
      try {
        const { responseData } = await client.get({
          endpoint: ep.prioritizeFieldsAPI,
          data: { name_only: 1 },
        })
        const { results } = responseData
        setPrioritizeFields(results)
      } catch (e) {
        console.log('Could not complete request, please try again later.')
      }
    }
    getPrioritizeFields()
  }, [client, ep.prioritizeFieldsAPI])

  React.useEffect(() => {
    async function getPartitionFields() {
      try {
        const { responseData } = await client.get({
          endpoint: ep.partitionFieldsAPI,
          data: { name_only: 1 },
        })
        const { results } = responseData
        setPartitionFields(results)
      } catch {
        console.log('Could not complete request, please try again later.')
      }
    }
    getPartitionFields()
  }, [client, ep.partitionFieldsAPI])

  const Title = (
    <TitleDiv>
      <span>
        <FavoriteButton
          status={exportState.favorite}
          updateFavoriteStatus={() => {
            setExportState((state: any) => ({
              ...state,
              favorite: !exportState.favorite,
            }))
          }}
        />
      </span>
      <span>Export</span>
      <span style={{ color: colors.gray5 }}> / </span>
      <span style={{ color: colors.gray8 }}>{exportState?.name}</span>
    </TitleDiv>
  )

  const Content = (
    <ContentDiv>
      <BaseGeneralSection
        chosenExport={props.chosenExport}
        exportState={exportState}
        setExportState={setExportState}
        layouts={layoutList}
        audienceInfo={props.audienceInfo}
        disabled={props.disabled}
        dialogType={props.dialogType}
        userData={userData}
        showAppendsCheckbox={showAppendsCheckbox}
      />
      <LimitsAndOrderingSection
        exportState={exportState}
        setExportState={setExportState}
        prioritizeFields={prioritizeFields}
        partitionFields={partitionFields}
        chosenExport={props.chosenExport}
        disabled={props.disabled}
      />
      <FileConfigurationSection
        delimiter={exportState.delimiter}
        stripDelimiterAndQuotes={exportState.strip_delimiter_and_quotes}
        quotes={exportState.quotes}
        compression={exportState.compression}
        header={exportState.header}
        disabled={props.disabled}
        setExportState={setExportState}
        client={client}
        dialogType={props.dialogType}
      />
    </ContentDiv>
  )

  const UserConfirmationText = () => {
    if (
      props.creditsToBeUsed === null ||
      typeof props.creditsToBeUsed === 'undefined'
    ) {
      return (
        <div>
          <span>Calculating credit usage . . .</span>
          <LinearProgress />
        </div>
      )
    } else if (((props.creditsToBeUsed as unknown) as number) > 0) {
      return (
        <span>
          Are you sure you want to continue this export? Doing so will use{' '}
          {props.creditsToBeUsed !== null ? (
            Number(props.creditsToBeUsed).toLocaleString()
          ) : (
            <Skeleton width={50} />
          )}{' '}
          of your remaining {Number(props.credits).toLocaleString()} credits.
        </span>
      )
    } else {
      return (
        <span>
          No credits will be used for this export. Please click confirm to continue:
        </span>
      )
    }
  }

  // Content section should not render until the chosen export has been fetched.
  // Previous code sets chosenExport = {}. exportState is derived from chosenExport, and is updated asynchronously
  // (due to how react handles updates tied to state). So we have to wait for the exportState keys to be set.
  let isLoaded = Object.keys(exportState).length > 0
  if (props.dialogType === 'run') {
    isLoaded = !!chosenExport.hashed
  }

  const DynamicContent = isLoaded ? Content : <Skeleton width={782} height={507} />
  return (
    <StandardDialog
      isOpen={props.isOpen}
      title={Title}
      Content={DynamicContent}
      handleClose={exit}
      dialogStyle={{ padding: '1rem' }}
      ActionButtons={
//        props.dialogType !== 'view' && (
//          <RequestUserConfirmation
//            text={UserConfirmationText()}
//            Component={
              <Button
                text={props.dialogType === 'run' ? 'Run' : 'Save'}
                variant="primary"
                disabled={!isLoaded}
                size="large"
                id="run_export"
                onClick={() => {
                  submitExport()
                }}
                style={{ margin: '0.5rem' }}
                testId="runSaveExport"
              />
//            }
//            requestTrigger={props.dialogType === 'run' && props.credits !== null}
//            disableButtons={props.creditsToBeUsed === null}
//            onOpenCallback={() => {
//              calculateCreditUsage?.(chosenExport.hashed)
//            }}
//          />
//        )
      }
    />
  )
}

const ContentDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 750px;
  min-height: 600px;
`

const TitleDiv = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
