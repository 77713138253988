import { AutocompleteRenderGroupParams, TextField } from '@mui/material'
import { ReactComponent as ChevronDown } from 'icons/16px/Chevron/Down.svg'
import { StyledAutocomplete, StyledListBox } from './StandardAutocomplete/Css'
import { listStyle } from 'components/StandardAutocomplete'
import Tooltip from './Tooltip/Tooltip'
import { ChangeEvent, SyntheticEvent } from 'react'

type Option = { group: string; value: any; label: string }
interface Props {
  value: string
  options: Array<Option>
  onChange: (value: string) => void
  highlightGroup: string
  placeholder: string
  disabledGroup?: string
  style?: any
  testId?: string
  tooltip?: boolean
}

const GroupedAutoComplete: React.FC<Props> = (props) => {
  // Order first by the highlighted group, then alphabetically.
  // Prefixing the string with '-' guarantees that the group
  // that is to be highlighted has precedence.
  const orderedOptions = props.options.sort((a, b) => {
    const aPseudo = props.highlightGroup === a.group ? '-' + a.label : a.label
    const bPseudo = props.highlightGroup === b.group ? '-' + b.label : b.label
    return aPseudo.localeCompare(bPseudo)
  })

  return (
    <StyledAutocomplete
      value={props.value}
      onChange={(_: any, option: unknown) => 
        props.onChange((option as Option)?.value)}
      options={orderedOptions}
      placeholder={props.placeholder}
      disableClearable={true}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            inputProps={{ ...params.inputProps, 'data-cy': props?.testId }}
          />
        )
      }}
      renderGroup={(option: AutocompleteRenderGroupParams) => (
        <>
          <div style={{ padding: '0.5rem' }}>{option.group}</div>
          <div style={{ backgroundColor: 'white' }}>{option.children}</div>
          {option.group === props.highlightGroup && <hr />}
        </>
      )}
      isOptionEqualToValue={(option: unknown, value: unknown) => {
        const optionValue = (option as Option).value
        switch (typeof value) {
          case 'string':
            return optionValue === value
          case 'object':
            return optionValue === (value as Option).value
          default:
            return false
        }
      }}
      getOptionDisabled={(option: any) =>
        (option as Option).group === props.disabledGroup
      }
      PaperComponent={(params: any) => <StyledListBox {...params} />}
      // renderOption now takes a 'props: object' property which needs to be included
      // in the function definition, props then needs to be spread as and attribute
      // on the main element of the returned component 
      renderOption={(optionProps: object, option: any) => {
        if (props.tooltip) {
          return (
            <Tooltip
              data-cy={`groupedAutocomplete-tooltip-${props.testId}`}
              title={option.label}
              key={option.label}
              {...optionProps}
            >
              <div>{option.label}</div>
            </Tooltip>
          )
        }
        return <div {...optionProps} key={option.label}>{option.label}</div>
      }}
      getOptionLabel={(option: any) => {
        if (typeof option === 'string') return option
        return option.label || ""
      }}
      popupIcon={<ChevronDown />}
      ListboxProps={{ style: listStyle }}
      groupBy={(option: any) => option.group}
      size="small"
      style={{ ...props?.style }}
    />
  )
}

export default GroupedAutoComplete
