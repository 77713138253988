import StandardDialog from '../../../../components/StandardDialog'
import Button from '../../../../components/Button/'
import { useHistory } from 'react-router-dom'
import MatchContent from './ExactMatchDialog/MatchContent'
import { useEffect, useState } from 'react'
import MatchingCleanse from './ExactMatchDialog/MatchCleanse'
import { bestFuzzyMatch } from '../../../../utils/fuzzyMatch'
import { LinearProgress } from '@mui/material'
import { useAlerts } from '../../../../context/alert-context'
import tm from '../../../../analytics/TagManager'
import { InitialState } from '../initialState'
import UploadInterface from 'components/UploadInterface'

type OmittedKeys =
  | 'dialogIsOpen'
  | 'file_uuid'
  | 'fileAlreadyUploaded'
  | 'field_to_cleansing_method_map'
  | 'matchesExpanded'
  | 'uploadsExpanded'

interface Props extends Omit<InitialState, OmittedKeys> {
  initialState: InitialState
  makeMatchState: (update: any) => void
  isOpen: boolean
  setMatchState: React.Dispatch<React.SetStateAction<InitialState>>
  submit: () => Promise<void>
  handleClose: () => Promise<void>
  onDropAccepted: any
  onDropRejected: any
  sizeLimitMb?: number
}

export default function ExactMatchDialog(props: Props) {
  const history = useHistory()
  const fileColumns = props.currentFile?.file_columns || []
  const [exactMatchWarning, setExactMatchWarning] = useState('')
  const { sendAlert } = useAlerts()

  useEffect(() => {
    if (exactMatchWarning && !props.isOpen) {
      setExactMatchWarning('')
    }
  }, [props.isOpen, exactMatchWarning])

  function updateMatch(idx: any, update: any) {
    const _matchFields = [...props.match_fields]

    if (update.file_field) {
      const fuzzyMatch = bestFuzzyMatch(update.file_field, props.dbFields)
      if (fuzzyMatch) update.database_field = fuzzyMatch
    }

    _matchFields[idx] = { ..._matchFields[idx], ...update }
    props.setMatchState((state: any) => ({
      ...state,
      match_fields: _matchFields,
    }))
  }

  function addMatch() {
    const _matchFields = [...props.match_fields]
    _matchFields.push({ database_field: 'company_domain', file_field: '' })
    props.setMatchState((state: any) => ({
      ...state,
      match_fields: _matchFields,
    }))
  }

  function removeMatch(idx: any) {
    const _matchFields = props.match_fields.filter((_: any, i: any) => i !== idx)
    props.setMatchState((state: any) => ({
      ...state,
      match_fields: _matchFields,
    }))
  }

  function submitFileMatch() {
    let error = false
    props.match_fields.forEach((match: any) => {
      error = !(match.database_field && match.file_field)
    })

    if (!props.fileName.trim()) {
      sendAlert({ message: 'Please enter a file name' })
      return
    }

    if (error) {
      const text = props.isCreatingDerivedAudience
        ? 'Select matches to create audience'
        : 'Please make selections or turn off'
      setExactMatchWarning(text)
      return
    }
    props.submit()
    tm.captureCustomEvent('uploads_upload_and_match')
  }

  const Uploader = (
    <UploadInterface
      onDropAccepted={props.onDropAccepted}
      onDropRejected={props.onDropRejected}
      sizeLimitMb={props.sizeLimitMb}
    />
  )

  function loadAudience() {
    history.push(`audiences/${props.audienceHash}`)
  }

  let buttonText
  if (props.isCreatingDerivedAudience) {
    if (!props.hasSubmitted) {
      buttonText = 'Create Audience'
    } else {
      buttonText = 'Load Audience'
    }
  } else if (!props.hasSubmitted) {
    buttonText = 'Upload' + (props.matchEnabled ? ' & Match' : '')
  } else {
    buttonText = 'Load Audience'
  }

  const ActionButtons = props.hasDropped && (
    <Button
      text={buttonText}
      disabled={buttonText === 'Load Audience' && !props.audienceHash}
      id="exact_match_button"
      onClick={!props.hasSubmitted ? submitFileMatch : loadAudience}
      variant="primary"
      style={{
        display: !props.matchEnabled && props.hasSubmitted ? 'none' : 'flex',
      }}
      testId="uploadAndMatchButton"
    />
  )

  const Content = !props.hasDropped ? (
    Uploader
  ) : !props.hasSubmitted ? (
    props.pctComplete === 1 ? (
      <MatchingCleanse
        initialState={props.initialState}
        dbFields={props.dbFields}
        fileName={props.fileName}
        privacy={props.privacy}
        cleansingOptions={props.cleansingOptions}
        cleansingMap={props.cleansingMap}
        matchEnabled={props.matchEnabled}
        addMatch={addMatch}
        updateMatch={updateMatch}
        setMatchState={props.setMatchState}
        setExactMatchWarning={setExactMatchWarning}
        match_fields={props.match_fields}
        exactMatchWarning={exactMatchWarning}
        fileColumns={fileColumns}
        removeMatch={removeMatch}
        isCreatingDerivedAudience={props.isCreatingDerivedAudience}
      />
    ) : (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '425px',
          rowGap: '1rem',
        }}
      >
        <div>Uploading File</div>
        <LinearProgress />
      </div>
    )
  ) : (
    <MatchContent
      matchEnabled={props.matchEnabled}
      audienceHash={props.audienceHash}
      matchStats={props.matchStats}
    />
  )

  return (
    <StandardDialog
      title="Upload"
      Content={Content}
      ActionButtons={props.pctComplete === 1 && ActionButtons}
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      fullWidth
      maxWidth="sm"
    />
  )
}

export type { Props }
