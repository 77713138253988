import styled from 'styled-components'
import { ReactComponent as Close } from '../../../../../../icons/16px/Close.svg'
import { colors, typography } from '../../../../../../context/theme-context/'

const { medium } = typography.bodyRegular

const LayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  border-radius: 0.25rem;
  font-size: ${medium.fontSize};
  font-weight: ${medium.fontWeight};
  line-height: ${medium.lineHeight};
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
`

const LayerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin-bottom: 1rem;
`

const StyledClose = styled(Close)`
  cursor: pointer;
  rect:nth-child(1) {
    stroke: ${colors.gray5};
  }
  rect:nth-child(n + 2) {
    fill: ${colors.gray7};
  }
  &:hover {
    fill: ${colors.red1};
    rect:nth-child(1) {
      stroke: ${colors.red3};
    }
    rect:nth-child(n + 2) {
      fill: ${colors.red5};
    }
  }
  :active {
    fill: ${colors.red1};
    rect:nth-child(1) {
      stroke: ${colors.red8};
    }
    rect:nth-child(n + 2) {
      fill: ${colors.red8};
    }
  }
`

const EditSpan = styled.span`
  margin: 0rem 0.5rem;
  line-height: 1rem;
  color: ${colors.blue6};
  cursor: pointer;
`

export { LayerContainer, LayerHeader, StyledClose, EditSpan }
