import styled, { css } from 'styled-components'
import { colors, typography } from '../../context/theme-context'

const { medium } = typography.bodyRegular

const StyledInput = styled.input`
  flex: 1;
  font-family: Rubik;
  font-size: ${medium.fontSize};
  font-weight: ${medium.fontWeight};
  line-height: ${medium.lineHeight};
  background-color: inherit;
  border: none;
  outline: none;
  ::placeholder {
    color: ${colors.gray6};
  }
  padding: calc(0.5rem + 1px) 0.5rem; // TODO: revisit, done to match autocomplete height
  border-radius: 0.25rem;
`

const InputContainer = styled.div<{ disabled?: boolean; focused?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  border: 1px solid ${colors.gray6};
  border-radius: 0.25rem;
  &:hover {
    ${(props) =>
      !props.disabled &&
      !props.focused &&
      css`
        border: 1px solid ${colors.blue3};
      `}
  }
  :disabled {
    background-color: ${colors.gray3};
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${colors.gray3};
    `}
  ${(props) =>
    props.focused &&
    css`
      border: 1px solid ${colors.blue6};
    `}
`

export { StyledInput, InputContainer }
