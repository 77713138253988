import BasicTable from '../../../../../components/BasicTable'
import LinearProgress from '@mui/material/LinearProgress'
import { ReactComponent as CheckMark } from '../../../../../icons/16px/Check.svg'
import { colors } from '../../../../../context/theme-context/'

export interface MatchStat {
  sort_order: number
  statistic: string
  value?: number
}

interface MatchContentI {
  matchStats: Array<MatchStat>
  matchEnabled?: boolean
  audienceHash?: string
}

const MatchContent: React.FC<MatchContentI> = ({
  matchEnabled,
  audienceHash,
  matchStats,
}) => {
  let statusMessage
  if (!audienceHash && !matchStats?.length) {
    statusMessage = 'Processing File'
  } else {
    statusMessage = 'Generating Match Statistics'
  }

  if (!!matchStats?.length) {
    const rows = matchStats
      .sort((a, b) => a.sort_order - b.sort_order)
      .map((entry) => ({
        statistic: entry.statistic,
        value: entry.value?.toLocaleString(),
      }))

    return (
      <div style={{ height: '400px', fontSize: '0.875rem' }}>
        <div
          style={{
            display: 'flex',
            columnGap: '0.5rem',
            alignItems: 'center',
            padding: '0rem 0rem 1rem',
          }}
        >
          <CheckMark
            width={'1rem'}
            height={'1rem'}
            stroke={colors.green8}
            fill={colors.green8}
          />
          <span data-cy="fileUploaded">File Uploaded</span>
        </div>
        <BasicTable rows={rows} />
      </div>
    )
  }
  return (
    <div style={{ height: '400px', fontSize: '0.875rem' }}>
      <div style={{ padding: '0rem 0rem 1rem' }}>
        Please wait while we upload your file
        {matchEnabled
          ? ` and generate your matched records
        and statistics. You can also exit and find your matched audiencies later
        on the uploads page.`
          : '.'}
      </div>
      <div style={{ padding: '1rem 0rem 1.5rem' }}>{statusMessage}</div>
      <LinearProgress />
    </div>
  )
}

export default MatchContent
