import styled, { css } from 'styled-components'
import { typography, colors } from '../../context/theme-context'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as DeleteIcon } from '../../icons/16px/Close.svg'

const { medium, small } = typography.bodyRegular

const ContentDiv = styled.span<{ text?: string; size?: string }>`
  display: flex;
  align-items: center;
  font-size: ${medium.fontSize};
  line-height: ${medium.lineHeight};
  font-weight: ${medium.fontWeight};
  padding: calc(0.5rem - 1px);
  border-radius: 0.5rem;
  background: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  cursor: default;
  &:hover {
    background: ${colors.gray3};
  }
  :active {
    background: ${colors.gray4};
  }
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${small.fontSize};
      border-radius: 0.25rem;
      padding: 0.25rem;
    `};
  ${(props) =>
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    props.text[0] === '-' &&
    css`
      background: ${colors.red1};
      border: 1px solid ${colors.red4};
      color: ${colors.red9};
    `};
`

const ProgressIndicator = styled(CircularProgress)`
  display: block;
  margin-right: 0.25rem;
  color: ${colors.green8};
`

const TextDiv = styled.div`
  margin-right: 0.25rem;
`

const NumberDiv = styled.div`
  margin-right: 0.25rem;
  color: ${colors.gray7};
`

const DeleteDiv = styled.div<{ text?: string }>`
  svg > rect:nth-child(1) {
    stroke: ${colors.gray5};
    fill: ${colors.gray2};
  }
  svg > rect:nth-child(1n + 2) {
    stroke: ${colors.gray7};
    fill: ${colors.gray7};
    stroke-width: 0.5px;
  }
  ${(props) =>
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    props.text[0] === '-' &&
    css`
      svg > rect:nth-child(1) {
        fill: ${colors.red1};
        stroke: ${colors.red4};
      }
      svg > rect:nth-child(1n + 2) {
        stroke: ${colors.red7};
        fill: ${colors.red7};
      }
    `}
`

const StyledDelete = styled(DeleteIcon)`
  display: block;
  &:hover {
    background: ${colors.gray3};
  }
`

export { ContentDiv, TextDiv, NumberDiv, DeleteDiv, ProgressIndicator, StyledDelete }
